import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PageHeaderCustom, ContentCustom } from '../../components';
import { FolderStatusStats } from '../../components/FolderStatus/FolderStatusStats';

export const Home = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);

  const getFolderStats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/home'
      });
      setTotals(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await getFolderStats();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <FolderStatusStats totals={totals} />
      <ContentCustom />
    </>
  );
};
