import { useState, useEffect, useCallback } from 'react';
import { Form, Input, Select, Button, Radio } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

export const useFields = (purpose) => {
  const { user } = useAuthContext();
  const { message } = useErrorMessage();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['gender'],
      rules: [{ required: true }],
      valuePropName: 'value',
      label: 'genders',
      input: (
        <Radio.Group>
          {(enums?.genders || []).map((gender) => (
            <Radio.Button key={gender} value={gender}>
              {t(`users.form.gender.${gender}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['health_insurance_id'],
      rules: [{ required: true }],
      input: (
        <Form.List name="health_insurance_id" initialValue={['']}>
          {(fieldsComment, { add, remove }) => (
            <>
              {fieldsComment.map((field) => (
                <div key={field.name}>
                  <Form.Item {...field} key={[field.name]} name={[field.name]}>
                    <Input disabled={!isAdmin} />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => isAdmin && remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => isAdmin && add()}>
                  {t('prescribers.form.addInsurance_id')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['rpps_number'],
      rules: [{ required: true }]
    },
    {
      name: ['speciality'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.specialities || []).map((speciality) => (
            <Option key={speciality} value={speciality}>
              {t(`users.form.${speciality}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: purpose === 'edit'
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
