import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { folderStatuses } from '../../utils/constants/tagColors';
import usePatientContext from './PatientContext';
import { getDate } from '../../utils/formatters';

export const useColumns = () => {
  const { t } = useTranslation();
  const { enums } = usePatientContext();

  return [
    {
      title: t('patients.columns.invoice_folder_status'),
      key: 'invoice_folder_status',
      dataIndex: ['invoice_folder_status'],
      render: (invoice_folder_status) =>
        invoice_folder_status ? (
          <Tag color={folderStatuses[invoice_folder_status]}>
            {t(`patients.tags.${invoice_folder_status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      filters: enums?.folderStatuses?.map((f) => ({
        text: t(`patients.tags.${f}`),
        value: f
      }))
    },
    {
      title: t('patients.columns.folder_status'),
      key: 'folder_status',
      dataIndex: ['folder_status'],
      render: (folder_status) =>
        folder_status ? (
          <Tag color={folderStatuses[folder_status]}>
            {t(`patients.tags.${folder_status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      filters: enums?.folderStatuses?.map((f) => ({
        text: t(`patients.tags.${f}`),
        value: f
      }))
    },
    {
      title: t('patients.columns.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('patients.columns.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('patients.columns.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('patients.columns.social_security_number'),
      key: 'social_security_number',
      dataIndex: 'social_security_number',
      sorter: true
    },
    {
      title: t('patients.columns.date_of_birth'),
      key: 'date_of_birth',
      dataIndex: 'date_of_birth',
      sorter: true,
      render: (date_of_birth) => (date_of_birth ? getDate(date_of_birth) : '-')
    },
    {
      title: t('patients.columns.communicator_activation_date'),
      key: 'communicator_activation_date',
      dataIndex: 'communicator_activation_date',
      sorter: true,
      render: (communicator_activation_date) =>
        communicator_activation_date
          ? getDate(communicator_activation_date)
          : '-'
    },
    {
      title: t('patients.columns.communicator_last_activity_date'),
      key: 'communicator_last_activity_date',
      dataIndex: 'communicator_last_activity_date',
      sorter: true,
      render: (communicator_last_activity_date) =>
        communicator_last_activity_date
          ? getDate(communicator_last_activity_date)
          : '-'
    }
  ];
};
