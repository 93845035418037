export const userRoles = {
  PATIENT: 'green',
  CENTER: 'purple',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const userEmployers = {
  BOSTON: '#C6E8FE',
  TPA: '#E6CEFB'
};

export const centerType = {
  PUBLIC: '#C6E8FE',
  PRIVATE: '#E6CEFB'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};

export const folderStatuses = {
  COMPLETE: '#87DBD3',
  INCOMPLETE: '#C87F91'
};

export const paymentStatuses = {
  TO_RECONCILE: '#F39155',
  RECONCILIED: '#9FC7A3',
  REJECTED: '#F79CA7'
};

export const paymentTypes = {
  CREDIT: '#F39155',
  CLASSIC: '#9FC7A3'
};

export const remindersCount = {
  REMINDER1: '#9FC7A3',
  REMINDER2: '#F79CA7',
  REMINDER3: '#F39155'
};
