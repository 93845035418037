import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '..';
import useConfigurationContext from './ConfigurationContext';
import { CollapsableTable } from '../Collapsable/Collapsable';
import { dataFormatter, formatToJsx, indexFinder } from './utils';
import { AllPurposeModal } from '../Modal/Modal';

export const Configurations = () => {
  const { t } = useTranslation();
  const {
    addItem,
    editItem,
    fetchItems,
    forceRefresh,
    defaultItemTitles,
    defaultItemsEntries,
    defaultItemsColumns,
    modalTitle,
    setModalTitle,
    isModalOpen,
    setIsModalOpen,
    modalPath,
    setModalPath
  } = useConfigurationContext();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [defaultOpen, setDefaultOpen] = useState([]);
  const [defaultOpenChildren, setDefaultOpenChildren] = useState([]);

  const [defaultModalData, setDefaultModalData] = useState({});

  const loadFromDB = (resourceName) => {
    if (resourceName === 'psmacro') {
      fetchItems(`${resourceName}/get-all`, (data2) =>
        setData((prev) => ({ ...prev, [resourceName]: data2 }))
      );
      return;
    }
    fetchItems(resourceName, (data2) =>
      setData((prev) => ({ ...prev, [resourceName]: data2 }))
    );
  };

  useEffect(() => {
    setIsLoading(true);
    defaultItemTitles.forEach((item) => loadFromDB(`${item}`));
  }, [forceRefresh]);

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  return (
    <>
      <PageHeaderCustom title={t('configurations.title')} />
      <ContentCustom>
        <AllPurposeModal
          type={modalPath ? 'edit' : 'create'}
          path={modalPath}
          title={t(
            `configurations.${modalPath ? 'edit' : 'add'}.${modalTitle}`
          )}
          data={defaultItemsEntries[modalTitle]}
          defaultData={defaultModalData}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClose={(newItem) => {
            if (modalPath)
              editItem(newItem, modalPath?.split('/')[1], modalTitle);
            else addItem(newItem, modalTitle);
            setModalPath('');
          }}
          extra={
            data?.psmacro?.map((item) => ({
              value: item._id,
              label: item.title
            })) || []
          }
          fetchItems={fetchItems}
          setModalPath={setModalPath}
        />
        {!isLoading && (
          <CollapsableTable
            key="root"
            items={Object.keys(data)
              .sort(
                (a, b) =>
                  indexFinder(defaultItemTitles, a) -
                  indexFinder(defaultItemTitles, b)
              )
              .map((item, index) =>
                dataFormatter(
                  t,
                  data || [],
                  item === 'psmacro',
                  item === 'psmacro' ? 'psmicro' : '',
                  item,
                  '',
                  index
                )
              )}
            addItem={(type, ps_macro) => {
              if (ps_macro)
                setDefaultModalData({
                  macro_ps: ps_macro._id
                });
              setModalTitle(type);
              setIsModalOpen(true);
            }}
            defaultOpen={defaultOpen}
            defaultOpenChildren={defaultOpenChildren}
            onOpen={setDefaultOpen}
            onOpenChildren={setDefaultOpenChildren}
            accordion={false}
            subAccordion
            formatToJsx={(itemLocal) =>
              itemLocal &&
              formatToJsx(
                itemLocal,
                defaultItemsColumns[itemLocal[0]?.resourceName]
              )
            }
            disableAddButtonCondition={(resourceName) =>
              resourceName === 'account'
            }
          />
        )}
      </ContentCustom>
    </>
  );
};
