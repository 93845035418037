import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Col,
  Card,
  Form,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList,
  ListResource
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import PayingAgencyContactsDrawer from './PayingAgencyContactsDrawer';
import { useColumns } from './columnsContacts';

/**
 * Component for displaying details of a paying agency.
 * @returns {JSX.Element} - JSX for displaying details of a paying agency.
 */

export const ShowPayingAgency = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [payingagency, setPayingagency] = useState();
  const listContent = useListContent(payingagency);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [purpose, setPurpose] = useState(null);

  const openDrawer = (contactId) => {
    if (contactId) {
      setPurpose('edit');
      setIdFromOverlay(contactId);
    } else {
      setPurpose('create');
    }
    setIsDrawerOpen(true);
  };

  const columns = useColumns(openDrawer);

  const getPayingAgency = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payingagencies/${id}?populate=comments.author`
      });
      setPayingagency(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deletePayingAgency = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/payingagency/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/payingagency/comments/${id}`,
        body
      });
      form.resetFields();
      getPayingAgency();
    } catch (e) {
      message(e);
    }
  };

  const closeDrawer = () => {
    setIdFromOverlay(null);
    setPurpose(null);
    setIsDrawerOpen(false);
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getPayingAgency();
    })();
  }, [getPayingAgency]);

  return (
    <>
      <PageHeaderCustom
        title={t('payingagencies.show.title')}
        extra={
          <>
            <Button type="primary" onClick={showDrawer}>
              {`${t('payingagencies.addCommentButton')} `}
            </Button>
            <Link to={{ pathname: `${routes.PAYING_AGENCIES}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deletePayingAgency}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        {isDrawerOpen && (
          <PayingAgencyContactsDrawer
            isDrawerOpen={isDrawerOpen}
            closeDrawer={closeDrawer}
            getPayingAgency={getPayingAgency}
            payingagency={id}
            idFromOverlay={idFromOverlay}
            purpose={purpose}
          />
        )}
        <Row gutter={[0, 24]}>
          <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <Text>{t(`enums.${payingagency?.type}`) || '-'}</Text>
                <DescriptionList data={listContent} translate />
              </Card>
              <Card style={{ marginTop: '20px' }}>
                <Button type="primary" onClick={() => openDrawer()}>
                  {t('payingagencies.form.contacts.buttonAddContact')}
                </Button>
                <ListResource
                  resourceName="contacts"
                  columns={columns}
                  resourceModelName="contacts"
                  withPageHeader={false}
                  withCreateButton={false}
                  withSearchBar={false}
                  showAction={false}
                  editAction={false}
                  deleteAction={false}
                  extraQuery={`paying_agency=${id}`}
                />
              </Card>
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
      {open && (
        <CommentsDrawer
          title={t('payingagencies.drawer.addComment.title')}
          onClose={onClose}
          open={open}
          size={size}
          updateResource={updateResource}
          form={form}
          models={payingagency}
        />
      )}
    </>
  );
};
