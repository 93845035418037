import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('prescribers.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('prescribers.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('prescribers.form.health_insurance_id'),
      key: 'health_insurance_id',
      dataIndex: 'health_insurance_id',
      sorter: true,
      render: (health_insurance_ids) => (
        <div>{health_insurance_ids.join(', ')}</div>
      )
    },
    {
      title: t('prescribers.form.rpps_number'),
      key: 'rpps_number',
      dataIndex: 'rpps_number',
      sorter: true
    },
    {
      title: t('prescribers.form.address.number'),
      key: 'address.number',
      dataIndex: 'address',
      render: (address) => (address?.number ? address.number : '-'),
      sorter: true
    },
    {
      title: t('prescribers.form.address.street'),
      key: 'address.street',
      dataIndex: 'address',
      render: (address) => (address?.street ? address.street : '-'),
      sorter: true
    },
    {
      title: t('prescribers.form.address.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => (address?.city ? address.city : '-'),
      sorter: true
    },
    {
      title: t('prescribers.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('payingagencies.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number),
      sorter: true
    }
  ];
};
