import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Popconfirm,
  Card,
  Form,
  List,
  Typography,
  Col,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { useListContentCaregiver } from './listContents/listContentCaregiver';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { CommentsButton } from '../../components/CommentsButton/CommentsButton';
import { PatientDetailCard } from './PatientDetailCard';
import { remindersCount } from '../../utils/constants/tagColors';

/**
 * Component for displaying details of a patient.
 * @returns {JSX.Element} - JSX for displaying patient details.
 */
export const ShowPatient = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatients] = useState({});
  const listContent = useListContent(patient);
  const listContentCaregiver = useListContentCaregiver(patient);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();

  const getPatients = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/${id}?populate=comments.author,payingagency_amc,payingagency_amo`
      });
      setPatients(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deletePatient = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/patients/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/patients/comments/${id}`,
        body
      });
      form.resetFields();
      getPatients();
    } catch (e) {
      message(e);
    }
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      await getPatients();
    })();
  }, [getPatients]);

  return (
    <>
      <ContentCustom>
        <PageHeaderCustom
          title={`${patient?.last_name} ${patient?.first_name}`}
          extra={
            <>
              <CommentsButton
                count={patient?.comments?.length}
                showDrawer={showDrawer}
              />
              <Link to={{ pathname: `${routes.PATIENTS}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deletePatient}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          }
        />
        <PatientDetailCard
          title={t('patients.form.titles.patient')}
          contentData={<DescriptionList data={listContent} translate />}
          isLoading={isLoading}
        />
        <PatientDetailCard
          title={t('patients.caregiver')}
          contentData={
            <DescriptionList data={listContentCaregiver} translate />
          }
          isLoading={isLoading}
        />
        <Card>
          <List
            size="small"
            header={t('patients.reminders_insurance_title')}
            bordered
            dataSource={patient.reminders_insurance_card}
            renderItem={(item, index) => (
              <List.Item
                style={{ display: 'flex', justifyContent: 'space-arround' }}
              >
                <Col>
                  <Tag color={remindersCount[`REMINDER${index + 1}`]}>
                    {index + 1}
                  </Tag>
                  <Typography.Text>
                    {t('patients.form.reminders.type')} :
                  </Typography.Text>{' '}
                  {t(`enums.${item.type}`)}
                </Col>
                <Col>
                  <Typography.Text>
                    {' '}
                    {t('patients.form.reminders.date')} :
                  </Typography.Text>{' '}
                  {moment(item.date).format('DD/MM/YYYY')}
                </Col>
              </List.Item>
            )}
          />
        </Card>
        <Card style={{ marginTop: '20px' }}>
          <List
            size="small"
            header={t('patients.reminders_prescriptions_title')}
            bordered
            dataSource={patient.reminders_prescription}
            renderItem={(item, index) => (
              <List.Item
                style={{ display: 'flex', justifyContent: 'space-arround' }}
              >
                <Col>
                  <Tag color={remindersCount[`REMINDER${index + 1}`]}>
                    {index + 1}
                  </Tag>
                  <Typography.Text>
                    {t('patients.form.reminders.type')} :
                  </Typography.Text>{' '}
                  {t(`enums.${item.type}`)}
                </Col>
                <Col>
                  <Typography.Text>
                    {' '}
                    {t('patients.form.reminders.date')} :
                  </Typography.Text>{' '}
                  {moment(item.date).format('DD/MM/YYYY')}
                </Col>
              </List.Item>
            )}
          />
        </Card>
      </ContentCustom>
      <CommentsDrawer
        title={t('patients.drawer.addComment.title')}
        onClose={onClose}
        open={open}
        size={size}
        updateResource={updateResource}
        form={form}
        models={patient}
      />
    </>
  );
};
