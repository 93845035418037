import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from './fieldsContacts';

/**
 * A drawer component for managing contacts paying agency.
 * This component displays a drawer with a form for creating or updating contacts paying agency.
 * @component
 * @param {boolean} isDrawerOpen - A boolean indicating whether the drawer is open.
 * @param {Function} closeDrawer - A function to close the drawer.
 * @param {Function} getPayingAgency - A function to fetch paying agencies data.
 * @param {Array<Object>} payingagencies - An array of contacts objects.
 * @param {String} payingagency - Id of paying agency.
 * @returns {JSX.Element} The JSX element representing the PayingAgencyContactsDrawer component.
 */
const PayingAgencyContactsDrawer = ({
  isDrawerOpen,
  closeDrawer,
  getPayingAgency,
  payingagency,
  idFromOverlay,
  purpose
}) => {
  const { t } = useTranslation();
  const { fields } = useFields(payingagency);

  const customNavigate = () => {
    closeDrawer();
    getPayingAgency();
  };

  return (
    <Drawer
      centered
      title={t(`payingagencies.form.contacts.buttonAddContact`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={1200}
      footer={false}
    >
      <CreateUpdateContainer
        customNavigate={customNavigate}
        purpose={purpose}
        fields={fields}
        baseUrl="contacts"
        resource="contacts"
        isOverlay
        withHeader={false}
        idFromOverlay={idFromOverlay}
      />
    </Drawer>
  );
};

PayingAgencyContactsDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  getPayingAgency: PropTypes.func,
  payingagency: PropTypes.string.isRequired,
  idFromOverlay: PropTypes.string,
  purpose: PropTypes.string
};

PayingAgencyContactsDrawer.defaultProps = {
  getPayingAgency: null,
  idFromOverlay: null,
  purpose: null
};

export default PayingAgencyContactsDrawer;
