import { Table } from 'antd';

export const dataFormatter = (
  t,
  items,
  subCollapse,
  subResourceName = '',
  resourceName = '',
  keyprefix = '',
  catIndex = undefined
) => {
  if (!items) return [];
  let toMap = [];

  const isCategory = catIndex !== undefined;

  if (isCategory) toMap = items[resourceName];
  else toMap = items;
  const formattedData = toMap?.map((item, index) => ({
    ...item,
    key: `${keyprefix}${index.toString()}`,
    id: item._id || item.id || '',
    label: item.title,
    subCollapse: false,
    resourceName: isCategory ? resourceName : subResourceName,
    subResourceName: isCategory ? subResourceName : '',
    children: subCollapse
      ? dataFormatter(
          t,
          item.children,
          false,
          isCategory ? subResourceName : '',
          '',
          `${keyprefix}${index.toString()}-`
        )
      : item.children,
    displayLabel: item.label
  }));

  if (isCategory) {
    return {
      key: `CAT-${catIndex.toString()}`,
      label: t(`configurations.titles.${resourceName}`),
      subCollapse,
      resourceName,
      subResourceName,
      children: formattedData
    };
  }
  return formattedData;
};

export const indexFinder = (array, item) =>
  array.findIndex((el) => el === item);

export const formatToJsx = (item, columns) => (
  <Table dataSource={item} columns={columns} pagination={false} bordered />
);
