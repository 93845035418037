import { useTranslation } from 'react-i18next';
import { getDate } from '../../utils/formatters';

export const useColumnsPrescriptions = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('prescriptions.form.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true
    },
    {
      title: t('prescriptions.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true
    },
    {
      title: t('prescribers.form.last_name'),
      key: 'prescriber_last_name',
      render: (prescription) =>
        prescription?.patient?.last_name
          ? prescription?.patient?.last_name
          : '-',
      sorter: true
    },
    {
      title: t('prescribers.form.first_name'),
      key: 'prescriber_first_name',
      render: (prescription) =>
        prescription?.patient?.first_name
          ? prescription?.patient?.first_name
          : '-',
      sorter: true
    },
    {
      title: t('prescribers.form.social_security_number'),
      key: 'prescriber_social_security_number',
      render: (prescription) =>
        prescription?.patient?.social_security_number
          ? prescription?.patient?.social_security_number
          : '-',
      sorter: true
    },
    {
      title: t('prescribers.form.date_of_birth'),
      key: 'prescriber_date_of_birth',
      render: (prescription) =>
        prescription?.patient?.date_of_birth
          ? getDate(prescription.patient.date_of_birth)
          : '-',
      sorter: true
    }
  ];
};
