import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Flex, Typography } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document as PdfDocument, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Renders a PDF document with navigation buttons for paging through the document.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.currentFile - The current file object containing the blob of the PDF document.
 * @param {number} props.pageNumber - The current page number.
 * @param {Function} props.setPageNumber - The function to update the current page number.
 * @returns {JSX.Element} The rendered PDF document component.
 */
export const Document = ({ currentFile, pageNumber, setPageNumber }) => {
  const { t } = useTranslation();
  const [numOfPages, setNumOfPages] = useState();
  const isLastPage = pageNumber === numOfPages;
  const isFirstPage = pageNumber === 1;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  const nextPage = () => {
    const nextPageNumber = pageNumber + 1;
    if (nextPageNumber <= numOfPages) {
      setPageNumber(nextPageNumber);
    }
    window.scrollTo(0, 0);
  };

  return (
    <PdfDocument file={currentFile} onLoadSuccess={onDocumentLoadSuccess}>
      <Page
        scale={0.8}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        pageNumber={pageNumber}
      />
      <Flex gap="middle" align="center" justify="center">
        <Button
          disabled={isFirstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          {t('buttons.previous')}
        </Button>
        <Typography>{`${pageNumber} / ${numOfPages}`}</Typography>
        <Button
          onClick={() => nextPage()}
          type={isLastPage ? 'primary' : 'default'}
        >
          {t('buttons.next')}
        </Button>
      </Flex>
    </PdfDocument>
  );
};

Document.propTypes = {
  currentFile: PropTypes.shape({
    url: PropTypes.string
  }),
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func
};

Document.defaultProps = {
  currentFile: {},
  pageNumber: 1,
  setPageNumber: () => {}
};
