import PropTypes from 'prop-types';
import {
  Button,
  Row,
  Col,
  Drawer,
  Avatar,
  Form,
  Input,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';

export const CommentsDrawer = ({
  title,
  onClose,
  open,
  size,
  updateResource,
  form,
  models
}) => {
  const { TextArea } = Input;
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { Text } = Typography;

  /**
   * Display a formatted date
   *
   * @function
   * @param {String} dateString - Current date of the message
   * @returns {string} - Date to display
   */
  const displayDate = (dateString) => {
    const dateObject = new Date(dateString);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    const formatedDate = `${day}/${month}/${year}`;

    return formatedDate;
  };

  const handleKeyPress = (event) => {
    const values = form.getFieldsValue();
    if (event.key === 'Enter' && !event.shiftKey) updateResource(values);
  };

  return (
    <Drawer
      title={title}
      onClose={onClose}
      open={open}
      size={size}
      footer={
        <Row gutter={[16, 16]}>
          <Col span={2}>
            <Avatar
              size={32}
              style={{ backgroundColor: '#87d068', verticalAlign: 'top' }}
            >
              {user.last_name.charAt(0).toUpperCase()}
            </Avatar>
          </Col>
          <Col span={22}>
            <Form
              name="add_comment_show_prescribers"
              onFinish={updateResource}
              form={form}
              onKeyDown={handleKeyPress}
            >
              <Form.Item name="comment" initialValue="">
                <TextArea
                  style={{ resize: 'none' }}
                  rows={3}
                  placeholder={t('prescribers.drawer.addComment.placeholder')}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                {`${t('prescribers.drawer.addComment.buttonAdd')} `}
              </Button>
            </Form>
          </Col>
        </Row>
      }
    >
      {(models?.comments || []).map((comment) => (
        <div key={comment?._id}>
          <Row gutter={[16, 16]}>
            <Col span={2}>
              <Avatar
                size={32}
                style={{ backgroundColor: '#87d068', verticalAlign: 'top' }}
              >
                {comment.author.last_name.charAt(0).toUpperCase()}
              </Avatar>
            </Col>
            <Col span={22}>
              <Text>
                {comment?.author.last_name || '-'}{' '}
                {comment?.author.first_name || '-'} {displayDate(comment?.date)}
                <p>{comment?.content}</p>
              </Text>
            </Col>
          </Row>
        </div>
      ))}
    </Drawer>
  );
};

CommentsDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  updateResource: PropTypes.instanceOf(Promise).isRequired,
  form: PropTypes.shape({ getFieldsValue: PropTypes.func }).isRequired,
  models: PropTypes.shape({
    comments: PropTypes.shape([])
  }).isRequired
};
