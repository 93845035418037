import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from './fieldsContacts';

/**
 * A drawer component for managing contacts center.
 * This component displays a drawer with a form for creating or updating contacts center.
 * @component
 * @param {boolean} isDrawerOpen - A boolean indicating whether the drawer is open.
 * @param {Function} closeDrawer - A function to close the drawer.
 * @param {Function} getCenter - A function to fetch center data.
 * @param {String} purpose - Purpose edit or create.
 * @param {String} centerId - Id of center.
 * @param {String} idFromOverlay - contact id if edit purpose.
 * @param {String} typeContact - type of edit.
 * @returns {JSX.Element} The JSX element representing the CenterContactsDrawer component.
 */
const CenterContactsDrawer = ({
  isDrawerOpen,
  closeDrawer,
  getCenter,
  typeContact,
  purpose,
  idFromOverlay,
  centerId
}) => {
  const { t } = useTranslation();
  const { fields } = useFields(typeContact, centerId);

  const customNavigate = () => {
    closeDrawer();
    getCenter();
  };

  return (
    <Drawer
      centered
      title={t(`centers.form.buttonAddContact.${purpose}.${typeContact}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={1200}
      footer={false}
    >
      <CreateUpdateContainer
        purpose={purpose}
        fields={fields}
        baseUrl="contacts"
        resource="contacts"
        isOverlay
        withHeader={false}
        idFromOverlay={idFromOverlay}
        customNavigate={customNavigate}
      />
    </Drawer>
  );
};

CenterContactsDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  getCenter: PropTypes.func,
  typeContact: PropTypes.string.isRequired,
  purpose: PropTypes.string,
  idFromOverlay: PropTypes.string,
  centerId: PropTypes.string
};

CenterContactsDrawer.defaultProps = {
  getCenter: null,
  purpose: null,
  idFromOverlay: null,
  centerId: null
};

export default CenterContactsDrawer;
