import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('centers.form.soldto'),
      key: 'soldto',
      dataIndex: 'soldto',
      sorter: true
    },
    {
      title: t('centers.form.finess_number'),
      key: 'finess_number',
      dataIndex: 'finess_number',
      sorter: true
    },
    {
      title: t('centers.form.center_name'),
      key: 'center_name',
      dataIndex: 'center_name',
      sorter: true
    },
    {
      title: t('centers.form.address.city'),
      key: 'address',
      dataIndex: 'address',
      render: (address) => (address?.city ? address.city : '-'),
      sorter: true
    },
    {
      title: t('centers.form.sales_representative'),
      key: 'sales_representative_contacts',
      dataIndex: 'sales_representative_contacts',
      render: (sales_representative_contacts) =>
        sales_representative_contacts?.length > 0
          ? (sales_representative_contacts || []).map(
              (sale) => `${sale?.first_name} ${sale?.last_name} `
            )
          : ''
    },
    {
      title: t('centers.form.regional_director'),
      key: 'regional_manager_contacts',
      dataIndex: 'regional_manager_contacts',
      render: (regional_manager_contacts) =>
        regional_manager_contacts?.length > 0
          ? (regional_manager_contacts || []).map(
              (director) => `${director?.first_name} ${director?.last_name} `
            )
          : ''
    },
    {
      title: t('centers.form.last_comment'),
      key: 'comments',
      dataIndex: 'comments',
      render: (comments) =>
        comments &&
        comments.length > 0 && (
          <Popover
            title="Dernier commentaire"
            content={comments[comments.length - 1].content}
          >
            <ExclamationCircleOutlined style={{ fontSize: 18 }} />
          </Popover>
        )
    }
  ];
};
