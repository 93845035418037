import logger from './logger';

/* eslint-disable no-unused-vars */
export const formDependencies = async (
  dispatchAPI,
  message,
  value,
  formValues,
  resource,
  form,
  setFormValues,
  fields
  // eslint-disable-next-line require-await
) => {
  try {
    const test = 'check';
  } catch (e) {
    logger.error(e);
  }
};
