import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Col,
  Card,
  Form,
  Flex,
  Typography,
  Image
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  ArrowRightOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { Document } from './Document';

/**
 * Component for displaying details of a prescriber.
 * @returns {JSX.Element} - JSX for displaying details of a prescriber.
 */

export const ShowPrescription = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [prescription, setPrescriptions] = useState();
  const listContent = useListContent(prescription);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();
  const [documentType, setDocumentType] = useState();
  const [documentUrl, setDocumentUrl] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const { Text } = Typography;

  const getFile = async (file_id) => {
    if (!file_id) {
      return;
    }
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${file_id}`,
        responseType: 'blob'
      });

      const blob = new Blob([data], { type: data?.type });

      const url = window.URL.createObjectURL(blob);
      setDocumentType(data?.type);
      setDocumentUrl(url);
    } catch (e) {
      message(e);
    }
  };

  const getPrescriptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescriptions/${id}?populate=comments.author,center,patient,prescriber,prescription_file`
      });
      await getFile(data?.prescription_file?._id);
      setPrescriptions(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deletePrescriptions = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/prescriptions/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/prescriptions/comments/${id}`,
        body
      });
      form.resetFields();
      getPrescriptions();
    } catch (e) {
      message(e);
    }
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getPrescriptions();
    })();
  }, [getPrescriptions]);

  return (
    <>
      <PageHeaderCustom
        title={t('prescriptions.show.title')}
        extra={
          <>
            <Button type="primary" onClick={showDrawer}>
              {`${t('prescriptions.addCommentButton')} `}
            </Button>
            <Link to={{ pathname: `${routes.PRESCRIPTIONS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deletePrescriptions}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <DescriptionList data={listContent} translate />
              </Card>
            </Skeleton>
          </Col>
        </Row>
        <Row gutter={[0, 24]} style={{ marginTop: '20px' }}>
          <Col xs={8}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>Comming soon</Card>
            </Skeleton>
          </Col>
          <Col xs={16}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <Row gutter={[0, 24]}>
                  <Col xs={16}>
                    {documentType && documentType?.includes('pdf') && (
                      <Skeleton
                        active
                        loading={isLoading}
                        paragraph={{ rows: 5 }}
                      >
                        <Document
                          currentFile={documentUrl}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                        />
                      </Skeleton>
                    )}
                    {documentType &&
                      (documentType?.includes('png') ||
                        documentType?.includes('jpg') ||
                        documentType?.includes('jpeg')) && (
                        <Skeleton
                          active
                          loading={isLoading}
                          paragraph={{ rows: 5 }}
                        >
                          <Image width={500} src={documentUrl} />
                        </Skeleton>
                      )}
                  </Col>
                  <Col xs={8}>
                    <Card>
                      <Flex
                        style={{ gap: '20px', justifyContent: 'space-between' }}
                      >
                        <Text>
                          {dayjs(
                            prescription?.first_period_validity?.start_date
                          ).format('DD/MM/YYYY')}
                        </Text>
                        <ArrowRightOutlined />
                        <Text>
                          {dayjs(
                            prescription?.first_period_validity?.end_date
                          ).format('DD/MM/YYYY')}
                        </Text>
                        <CalendarOutlined />
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
      <CommentsDrawer
        title={t('prescriptions.drawer.addComment.title')}
        onClose={onClose}
        open={open}
        size={size}
        updateResource={updateResource}
        form={form}
        models={prescription}
      />
    </>
  );
};
