import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

/**
 * Component for displaying a list of prescriptions.
 * @returns {JSX.Element} - JSX for displaying a list of prescriptions.
 */

export const ListPrescriptions = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="prescriptions"
      columns={columns}
      headers={headers}
      resourceModelName="Prescriptions"
      populate="prescriber,center,patient"
    />
  );
};
