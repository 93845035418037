import { useState, useEffect, useCallback } from 'react';
import { Select, Radio, Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { AddIcon } from '../../utils/constants/customIcons';
import { noLabelLayout } from '../../utils/constants/formLayout';

const { Option } = Select;

/**
 * Custom hook for managing center fields form data and validation.
 * @param {function} openDrawer - Function to open a drawer.
 * @param {Array} prescribers - Array of prescribers.
 * @param {string} purpose - Purpose of the form (e.g., 'edit').
 * @returns {Object} - Object containing center fields data and validation rules.
 */
export const useCenterFields = (openDrawer, prescribers, purpose) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [soldtos, setSoldTos] = useState([]);
  const [enums, setEnums] = useState({});
  const { id } = useParams();

  const getSoldTo = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers' });
      const soldToValues = data.map((item) => {
        if (item._id !== id) {
          return item.soldto;
        }
        return [];
      });

      setSoldTos(soldToValues);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const validateSoldTo = (_, value) => {
    const isSoldToExists = soldtos?.some((s) => s === value);
    if (isSoldToExists) {
      return Promise.reject(new Error(t('Le soldTo existe déjà')));
    }
    return Promise.resolve();
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getSoldTo();
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const centerFields = [
    {
      name: ['soldto'],
      rules: [
        { required: true },
        {
          validator: validateSoldTo
        }
      ]
    },
    {
      name: ['center_name'],
      rules: [{ required: true }]
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      valuePropName: 'value',
      input: (
        <Radio.Group>
          {(enums?.types || []).map((type) => (
            <Radio key={type} value={type}>
              {t(`enums.${type}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    },
    {
      name: ['finess_number'],
      rules: [{ required: true }]
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: purpose === 'edit'
    },
    {
      name: ['address', 'number']
    },
    {
      name: ['address', 'street']
    },
    {
      name: ['address', 'city']
    },
    {
      name: ['address', 'postal_code']
    },
    {
      name: ['prescribers'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading} mode="multiple" allowClear>
          {(prescribers || []).map((prescriber) => (
            <Option key={prescriber._id} value={prescriber._id}>
              {prescriber.first_name} {prescriber.last_name} {'/ '}
              {prescriber?.health_insurance_id || 'n/a'} {'/ '}{' '}
              {prescriber?.rpps_number || 'n/a'}{' '}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['new_prescriber'],
      noLabel: true,
      input: (
        <Form.Item {...noLabelLayout}>
          <Button type="add" onClick={() => openDrawer('prescribers')}>
            {t('centers.form.addPrescriber')}
            <AddIcon />
          </Button>
        </Form.Item>
      )
    }
  ];

  return {
    centerFields
  };
};
