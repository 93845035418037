import { useTranslation } from 'react-i18next';
import { Input, Select, Form, Radio } from 'antd';
import usePatientContext from '../PatientContext';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field caregiver for patient billing data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useCaregiverFields = () => {
  const { enums } = usePatientContext();
  const { t } = useTranslation();
  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return [
    {
      name: ['caregiver_gender'],
      valuePropName: 'value',
      label: 'caregiver_gender',
      input: (
        <Radio.Group>
          {(enums?.genders || []).map((gender) => (
            <Radio.Button key={gender} value={gender}>
              {t(`users.form.gender.${gender}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      )
    },
    {
      name: ['caregiver_last_name']
    },
    {
      name: ['caregiver_first_name']
    },
    {
      name: ['caregiver_role'],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.relations || []).map((r) => (
            <Option key={r} value={r}>
              {t(`enums.${r}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'caregiver_phone_number.number',
      name: ['caregiver_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['caregiver_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['caregiver_phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['caregiver_email']
    }
  ];
};

export default useCaregiverFields;
