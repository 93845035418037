import { useTranslation } from 'react-i18next';
import { Tag, Button, Popconfirm, Divider } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  paymentStatuses,
  paymentTypes
} from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

/**
 * @hook useColumns
 * @description Returns columns for payments columns
 * @param {Object} enums - enums object
 * @param {Object} from - import from string
 * @returns {Array} columns
 */

export const useColumns = (enums, from, options) => {
  const { t } = useTranslation();

  return [
    {
      title: t('payments.columns.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (
        <Tag color={paymentTypes[type]}>{t(`payments.tags.${type}`)}</Tag>
      ),
      sorter: true
    },
    {
      title: t('payments.columns.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={paymentStatuses[status]}>
          {t(`payments.tags.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: enums?.status?.map((p) => ({
        text: t(`payments.tags.${p}`),
        value: p
      }))
    },
    {
      title: t('payments.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => amount && `${amount.toFixed(2)} €`,
      sorter: true
    },
    {
      title: t('payments.columns.amount_allocated'),
      key: 'amount_allocated',
      dataIndex: 'amount_allocated',
      render: (amount_allocated) =>
        amount_allocated && `${amount_allocated.toFixed(2)} €`,
      sorter: true
    },
    {
      title: t('payments.columns.operation_date'),
      key: 'operation_date',
      dataIndex: 'operation_date',
      render: (operation_date) =>
        operation_date && dayjs(operation_date).format('DD-MM-YYYY'),
      sorter: true
    },
    {
      title: t('payments.columns.payment_method'),
      key: 'payment_method',
      dataIndex: 'payment_method',
      sorter: true,
      render: (payment_method) =>
        payment_method && t(`payments.enums.payment_methods.${payment_method}`),
      filters: enums?.payment_methods?.map((value) => ({
        text: t(`payments.enums.payment_methods.${value}`),
        value
      }))
    },
    {
      title: t('payments.columns.label'),
      key: 'label',
      dataIndex: 'label',
      render: (label) => label || '-'
    },
    ...(from !== 'quittances'
      ? [
          {
            title: t('payments.columns.quittance'),
            key: 'quittance',
            dataIndex: 'quittance',
            render: (quittance) => (
              <Link
                to={{
                  pathname: `${routes.BILLING}${subRoutes.BILLING.QUITTANCES}/show/${quittance?._id}`
                }}
              >
                {(quittance && quittance?.code) || ''}
              </Link>
            ),
            sorter: true
          }
        ]
      : []),
    ...(from !== 'quittances'
      ? [
          {
            title: t('payments.columns.customer'),
            key: 'customer',
            dataIndex: 'customer',
            render: (customer) => (
              <Link
                to={{
                  pathname: `${routes.CUSTOMERS}/show/${customer?._id}`
                }}
              >
                {(customer && customer?.company_name) || ''}
              </Link>
            )
          }
        ]
      : []),
    {
      title: t('payments.columns.comment'),
      key: 'comment',
      dataIndex: 'comment',
      render: (comment) => comment || '-'
    },
    {
      key: 'action',
      align: 'right',
      render: (record) =>
        record?.status !== 'REJECTED' ? (
          <>
            <Button
              type="ghost"
              onClick={() => options.onEdit(record._id, true)}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => options.onArchived(record._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ fontSize: 18, color: 'red' }}
                type="delete"
              />
            </Popconfirm>
          </>
        ) : null
    }
  ];
};
