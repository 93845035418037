import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../../utils/formatters';

export const useListContentCaregiver = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'patients.form';
  const {
    caregiver_last_name,
    caregiver_first_name,
    caregiver_phone_number,
    caregiver_email,
    caregiver_role
  } = data;
  return [
    {
      label: t(`${translationKey}.caregiver_last_name`),
      span: 1,
      content: caregiver_last_name || '-'
    },
    {
      label: t(`${translationKey}.caregiver_first_name`),
      span: 1,
      content: caregiver_first_name || '-'
    },
    {
      label: t(`${translationKey}.caregiver_phone_number.number`),
      span: 1,
      content:
        caregiver_phone_number && caregiver_phone_number.number
          ? getPhoneNumber(caregiver_phone_number)
          : '-'
    },
    {
      label: t(`${translationKey}.caregiver_email`),
      span: 1,
      content: caregiver_email || '-'
    },
    {
      label: t(`${translationKey}.caregiver_role`),
      span: 1,
      content: caregiver_role ? t(`enums.${caregiver_role}`) : '-'
    }
  ];
};
