import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

export const ListPatients = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="patients"
      columns={columns}
      headers={headers}
      resourceModelName="Patient"
    />
  );
};
