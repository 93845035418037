import { useTranslation } from 'react-i18next';
import { Card, Flex } from 'antd';

/**
 * Component for displaying folder status statistics.
 * @param {Object[]} totals - An array containing objects representing the totals.
 * @param {string} totals[].type - The type of total.
 * @param {number} totals[].count - The count of items.
 * @param {number} totals[].total - The total count.
 * @returns {JSX.Element} - JSX for displaying folder status statistics.
 */

export const FolderStatusStats = (totals) => {
  const { t } = useTranslation();

  return (
    <Flex justify="space-around" style={{ marginTop: 30, width: '100%' }}>
      {(totals?.totals || []).map((total) => (
        <Card>
          <p>{t(`patients.columns.totals.${total.type}`)}</p>
          <p>{`${((total.count / total.total) * 100).toFixed(2)}%`}</p>
          <p>{`${total.count} / ${total.total}`}</p>
        </Card>
      ))}
    </Flex>
  );
};
