import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

export const ListCenters = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="centers"
      populate="sales_representative_contacts,regional_manager_contacts"
      columns={columns}
      headers={headers}
      resourceModelName="centers"
    />
  );
};
