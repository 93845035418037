import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import header from '../../assets/images/logostrategin.png';

const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [mail, setMail] = useState(null);

  const getMail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/mails/${id}`
      });
      let html = data.content;
      html = html.replace(/cid:headerImage/, header);
      setMail({ ...data, content: html });
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getMail();
    })();
  }, [getMail]);

  const deleteMail = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/mails/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={deleteMail}
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.delete')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        }
      />
      <ContentCustom>
        <Skeleton
          loading={isLoading}
          title={0}
          paragraph={0}
          avatar={{ size: 64 }}
          active
        >
          <p>{`Expéditeur: ${mail?.from?.name || 'N/A'} - ${
            mail?.from?.email || 'N/A'
          }`}</p>
          {mail &&
            (mail?.to || []).map((item) => (
              <p>{`Déstinataire: ${item?.name || 'N/A'} - ${
                item?.email || 'N/A'
              }`}</p>
            ))}
          {mail &&
            (mail?.cc || []).map((item) => (
              <p>{`cc: ${item?.name || 'N/A'} - ${item?.email || 'N/A'}`}</p>
            ))}
          <div style={{ height: '99vh' }}>
            <iframe
              title="email-content"
              srcDoc={mail?.content}
              width="100%"
              height="100%"
            />
          </div>
          ;
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowUser;
