import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Popconfirm, Table, message as antMessage } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

export const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  forceRefresh,
  showAction,
  editAction,
  deleteAction,
  duplicateAction,
  printAction,
  onDoubleClickAction,
  scroll,
  expandable,
  rowKey,
  setTotals
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);

      const searchURL = searchValue
        ? `/search/${encodeURIComponent(searchValue)}`
        : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers?.['x-total-count'] || 0, 10)
        });
        setResources(data);
        if (setTotals && data?.[0]?.totals) {
          setTotals(data?.[0]?.totals);
        }
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const duplicateResource = async (id) => {
    try {
      await dispatchAPI('POST', { url: `/${resourceName}/duplicate/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const printResource = async (id, fileName) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        contentType: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      antMessage.success(t('messages.success.download'));
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    navigate({
      pathname,
      search: `?p=${page.current}&pS=${page.pageSize}${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
    setPagination((prevState) => ({
      ...prevState,
      current: page.current,
      pageSize: page.pageSize
    }));
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {duplicateAction && (
            <>
              <CopyOutlined
                onClick={() => duplicateResource(record?.[rowKey])}
                style={{ fontSize: iconSize }}
              />
              <Divider type="vertical" />
            </>
          )}
          {showAction && (
            <>
              <Link
                to={{
                  pathname: showAction.pathname
                    ? showAction.pathname(record)
                    : `${path || pathname}/show/${record?.[rowKey]}`
                }}
              >
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
            </>
          )}
          {editAction && (
            <>
              <Link
                to={{
                  pathname: editAction.pathname
                    ? editAction.pathname(record)
                    : `${path || pathname}/edit/${record?.[rowKey]}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
            </>
          )}
          {printAction && (
            <>
              <PrinterOutlined
                onClick={() => printResource(record?.[rowKey])}
                style={{ fontSize: iconSize }}
              />
              <Divider type="vertical" />
            </>
          )}
          {deleteAction && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteResource(record?.[rowKey])}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
        </>
      )
    }
  ];

  const adjustedColumns = columns.map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach((f) => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      sortOrder: col.key === orderKey ? order : false,
      filteredValue: filters[col.key] ? filters[col.key].split(',') : []
    };
  });

  return (
    <Table
      style={style}
      scroll={scroll}
      rowKey={rowKey}
      rowClassName="rowStyle"
      onRow={(record) => ({
        ...(onDoubleClickAction
          ? {
              onDoubleClick: () =>
                onDoubleClickAction.action
                  ? onDoubleClickAction.action(record)
                  : navigate(`${path || pathname}/show/${record[rowKey]}`)
            }
          : {})
      })}
      dataSource={resources}
      loading={isLoading}
      onChange={handlePageChange}
      pagination={{ ...pagination, current: currentPage, pageSize }}
      columns={
        customActionColumn
          ? adjustedColumns
          : [...adjustedColumns, ...actionColumn]
      }
      expandable={
        !isLoading && resources.length && expandable
          ? {
              ...expandable,
              expandedRowKeys:
                expandable.expandedRowKeys || resources.map((r) => r.key)
            }
          : undefined
      }
    />
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string.isRequired,
      dataIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ).isRequired,
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string)
  }),
  rowKey: PropTypes.string,
  setTotals: PropTypes.func
};

Datatable.defaultProps = {
  path: null,
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  rowKey: '_id',
  setTotals: null
};
