export const headers = [
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'RPPS number',
    key: 'rpps_number'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'phone number country code',
    key: 'phone_number.country_code'
  },
  {
    label: 'phone number',
    key: 'phone_number.number'
  },
  {
    label: 'health insurance ID',
    key: 'health_insurance_id'
  }
];
