import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { getEnums } from '../../utils/getEnums';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

/**
 * Custom hook for managing form fields data and validation.
 * @param {string} typeContact - The type of contact for which fields are being configured.
 * @param {string} centerId - The if of the center if contact belong to center.
 * @returns {Object} - Object containing form fields data and loading state.
 */
export const useFields = (typeContact, centerId) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getSelectOptions = useCallback(() => {
    setIsLoading(true);
    getEnums(dispatchAPI).then((data) => {
      if (!data.error) {
        setEnums(data);
      } else {
        message(data.error.message);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      initialValue: typeContact,
      input: (
        <Select loading={isLoading} disabled={!!typeContact}>
          {enums?.types?.map((type) => (
            <Select.Option key={type} value={type}>
              {t(`enums.${type}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select loading={isLoading}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email']
    },
    ...(centerId
      ? [
          {
            name: ['center'],
            rules: [{ required: true }],
            hidden: true,
            initialValue: centerId
          }
        ]
      : [])
  ];

  return {
    fields,
    isLoading
  };
};
