import { Select, Upload, Button, DatePicker, Radio, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import usePatientContext from '../PatientContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

const usePayingAgencyFields = (purpose, id) => {
  const {
    payingAgency,
    setSelectedPayingAgency,
    healthInsurance = [],
    setHealthInsurance,
    uploadOrDeleteFile,
    isFileLoading,
    isSwitchAMC,
    setIsSwitchAMC,
    isSwitchInsurranceFile,
    setIsSwitchInsurranceFile
  } = usePatientContext();

  const { t } = useTranslation();

  return [
    {
      name: ['payingagency_amo'],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onSelect={(value) => setSelectedPayingAgency(value)}
          onClear={() => setSelectedPayingAgency(null)}
        >
          {(payingAgency || []).map((payingAgencies) => (
            <Option key={payingAgencies._id} value={payingAgencies._id}>
              {`${payingAgencies?.name_agency} / (${payingAgencies?.transmission_code})`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['insurance'],
      rules: [{ required: true }],
      input: (
        <Radio.Group
          onChange={(event) => setIsSwitchAMC(event.target.value)}
          options={[true, false].map((b) => ({
            label: t(`switch.${b}`),
            value: b
          }))}
        />
      )
    },
    ...(isSwitchAMC
      ? [
          {
            name: ['health_insurance'],
            rules: [{ required: true }],
            input: (
              <Upload
                multiple
                maxCount={2}
                beforeUpload={(file) => {
                  if (purpose !== 'edit') {
                    const newFile = [...healthInsurance];
                    newFile.push(file);
                    setHealthInsurance(newFile);
                    setIsSwitchInsurranceFile(true);
                  } else {
                    uploadOrDeleteFile(file, id, 'upload', 'health_insurance');
                  }
                  return false;
                }}
                onRemove={(doc) => {
                  if (purpose !== 'edit') {
                    const index = healthInsurance.indexOf(doc);
                    const newFile = healthInsurance.slice();
                    newFile.splice(index, 1);
                    setHealthInsurance(newFile);
                    setIsSwitchInsurranceFile(false);
                  } else {
                    uploadOrDeleteFile(
                      healthInsurance[
                        healthInsurance.findIndex(
                          (file) => file.metadata.originalName === doc.name
                        )
                      ],
                      id,
                      'delete',
                      'health_insurance'
                    );
                  }
                }}
                fileList={
                  isFileLoading
                    ? []
                    : (healthInsurance || []).map((file, index) => ({
                        uid: index,
                        name: file?.metadata?.originalName || file?.name,
                        status: 'done'
                      }))
                }
              >
                <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
              </Upload>
            )
          },
          {
            name: ['amc_card_validity'],
            input: <RangePicker />
          },
          ...(isSwitchInsurranceFile
            ? [
                {
                  name: ['payingagency_amc'],
                  input: <Select disabled />
                },
                {
                  name: ['amc_code'],
                  input: <Input disabled />
                },
                {
                  name: ['csr_code'],
                  input: <Input disabled />
                },
                {
                  name: ['convention_type'],
                  input: <Input disabled />
                }
              ]
            : [])
        ]
      : [])
  ];
};

export default usePayingAgencyFields;
