import { Route, Routes } from 'react-router-dom';
import { ListPayments } from './ListPayments';
import { Exception } from '../../components';

export const PaymentRouter = () => (
  <Routes>
    <Route index element={<ListPayments />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
