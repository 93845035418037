import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Form, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { CommentsButton } from '../../components/CommentsButton/CommentsButton';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import { routes } from '../../utils/constants/adminRoutes';
import { useColumns as useContactColumns } from '../payingAgency/columnsContacts';
import { useColumns as usePrescribersColumns } from './columnsPrescribers';
import CenterContactsDrawer from './CenterContactsDrawer';
import CustomCardSection from './CustomCardSection';

/**
 * Composant pour afficher les détails d'un centre avec différentes sections personnalisées.
 * @returns {JSX.Element} Composant ShowCenter.
 */
export const ShowCenter = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [center, setCenter] = useState();
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [typeContact, setTypeContact] = useState(null);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const listContent = useListContent(center);
  const prescribersColumns = usePrescribersColumns();

  const openDrawer = (contactId, type) => {
    if (contactId) {
      setPurpose('edit');
      setIdFromOverlay(contactId);
    } else {
      setPurpose('create');
    }
    setTypeContact(type);
    setIsDrawerOpen(true);
  };

  const contactsColumns = useContactColumns(openDrawer, typeContact);

  const prescribers = (center?.prescribers || [])
    .map((prescriber) => prescriber._id)
    .join(',');

  const getCenter = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/centers/${id}?populate=comments.author,prescribers,contacts`
      });
      setCenter(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  const closeDrawer = () => {
    setIdFromOverlay(null);
    setPurpose(null);
    setIsDrawerOpen(false);
  };

  const deleteCenter = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/centers/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/centers/comments/${id}`,
        body
      });
      form.resetFields();
      getCenter();
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getCenter();
    })();
  }, [getCenter]);

  const customCardSections = [
    {
      type: 'PRESCRIBER',
      extraQuery: `_id=${prescribers}`,
      columns: prescribersColumns,
      resourceName: 'prescribers'
    },
    {
      type: 'REGIONAL_MANAGER',
      buttonClick: () => openDrawer(null, 'REGIONAL_MANAGER'),
      extraQuery: `center=${id}&type=REGIONAL_MANAGER`,
      columns: contactsColumns,
      resourceName: 'contacts'
    },
    {
      type: 'SALES_REPRESENTATIVE',
      buttonClick: () => openDrawer(null, 'SALES_REPRESENTATIVE'),
      extraQuery: `center=${id}&type=SALES_REPRESENTATIVE`,
      columns: contactsColumns,
      resourceName: 'contacts'
    },
    {
      type: 'INVOICE_MANAGER',
      buttonClick: () => openDrawer(null, 'INVOICE_MANAGER'),
      extraQuery: `center=${id}&type=INVOICE_MANAGER`,
      columns: contactsColumns,
      resourceName: 'contacts'
    },
    {
      type: 'REMOTE_MONITORING',
      buttonClick: () => openDrawer(null, 'REMOTE_MONITORING'),
      extraQuery: `center=${id}&type=REMOTE_MONITORING`,
      columns: contactsColumns,
      resourceName: 'contacts'
    }
  ];

  return (
    <ContentCustom>
      {open && (
        <CommentsDrawer
          title={t('centers.drawer.addComment.title')}
          onClose={onClose}
          open={open}
          size={size}
          updateResource={updateResource}
          form={form}
          models={center}
        />
      )}
      {isDrawerOpen && typeContact && (
        <CenterContactsDrawer
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          getCenter={getCenter}
          typeContact={typeContact}
          purpose={purpose}
          idFromOverlay={idFromOverlay}
          centerId={center._id}
        />
      )}
      <PageHeaderCustom
        title={center?.center_name}
        extra={
          <>
            <CommentsButton
              count={center?.comments?.length}
              showDrawer={showDrawer}
            />
            <Link to={{ pathname: `${routes.CENTERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCenter}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <Card
          title={
            <span className="title-card">
              {t('centers.show.titles.CENTER')}
            </span>
          }
        >
          <DescriptionList data={listContent} translate />
        </Card>
      </Skeleton>
      {customCardSections.map((section) => (
        <CustomCardSection
          key={section.key}
          isLoading={isLoading}
          {...section}
        />
      ))}
    </ContentCustom>
  );
};
