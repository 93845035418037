import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

export const useListContent = (data = {}) => {
  const {
    name_agency,
    transmission_code,
    phone_number,
    email,
    follow_up_prodcedure,
    address,
    procedure_email,
    procedure_url
  } = data;
  const { t } = useTranslation();

  return [
    {
      label: 'payingagencies.form.name_agency',
      span: 1,
      content: name_agency || '-'
    },
    {
      label: 'payingagencies.form.transmission_code',
      span: 1,
      content: transmission_code || '-'
    },
    {
      label: 'payingagencies.form.phone_number',
      span: 1,
      content:
        phone_number && phone_number.number ? getPhoneNumber(phone_number) : '-'
    },
    {
      label: 'payingagencies.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'payingagencies.form.follow_up_prodcedure',
      span: 1,
      content: t(`enums.${follow_up_prodcedure}`) || '-'
    },
    ...(address?.city && address.number && address.postal_code
      ? [
          {
            label: 'payingagencies.form.address.city',
            span: 1,
            content: address?.city || '-'
          },
          {
            label: 'payingagencies.form.address.street',
            span: 1,
            content:
              address && address?.street && address?.number
                ? `${address.number}, ${address.street}`
                : '-'
          },
          {
            label: 'payingagencies.form.address.postcode',
            span: 1,
            content: address?.postal_code || '-'
          }
        ]
      : []),
    ...(procedure_email
      ? [
          {
            label: 'payingagencies.form.email',
            span: 1,
            content: procedure_email || '-'
          }
        ]
      : []),
    ...(procedure_url
      ? [
          {
            label: 'payingagencies.form.procedure_url',
            span: 1,
            content: procedure_url || '-'
          }
        ]
      : [])
  ];
};
