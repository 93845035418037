import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Col, Spin, Switch, DatePicker, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ListResource } from '../../components';
import { useColumns } from '../invoices/ShowCards/paymentsColumns';
import { headers } from './headers';
import PaymentModal from '../invoices/ShowCards/PaymentModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * @component
 * @name ListPayments
 * @description Displays the list of payments
 * @returns {component} ListPayments component
 */
export const ListPayments = ({ quittance }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [customers, setCustomers] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purpose, setPurpose] = useState(null);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isCredit, setIsCredit] = useState(true);
  const [filterDate, setFilterDate] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const openModal = () => {
    setPurpose('create');
    setIdFromOverlay();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPurpose(null);
    setIsModalOpen(false);
  };

  const onEdit = (idPayment) => {
    setPurpose('edit');
    setIdFromOverlay(idPayment);
    setIsModalOpen(true);
  };

  const onArchived = async (idPayment) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/payments/${idPayment}`
      });
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    }
  };

  const columns = useColumns(enums, 'payments', { onEdit, onArchived });

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getBankReconciliation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers`
      });
      setCustomers(data);
    } catch (e) {
      message(e);
    }
  };

  const customNavigate = () => {
    setPurpose(null);
    setIdFromOverlay(null);
    setIsModalOpen(false);
    setForceRefresh(!forceRefresh);
  };

  const onsearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startOfMonth = dates[0].startOf('month');
      const endOfMonth = dates[1].endOf('month');
      setFilterDate([startOfMonth, endOfMonth]);
    } else {
      setFilterDate(null);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCustomers();
      await getEnums();
      await getBankReconciliation();
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isModalOpen && (
        <PaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          idFromOverlay={idFromOverlay}
          customNavigate={customNavigate}
          purpose={purpose}
          quittance={quittance}
          enums={enums}
          bankReconciliations={bankReconciliations}
          customers={customers}
        />
      )}
      <Col span={24}>
        <Spin spinning={isLoading}>
          <ListResource
            resourceName="payments"
            populate="imported_by,quittance,customer"
            columns={columns}
            headers={headers}
            resourceModelName="Payments"
            extraQuery={`type=${isCredit ? 'CREDIT' : 'CLASSIC'}${
              filterDate?.length
                ? `&operation_date>=${dayjs(
                    new Date(filterDate[0]).setHours(0, 0, 0, 0)
                  ).toISOString()}&operation_date<=${dayjs(
                    new Date(filterDate[1]).setHours(23, 59, 59, 999)
                  ).toISOString()}`
                : ''
            }${selectedCustomer ? `&customer=${selectedCustomer}` : ''}`}
            withImportButton={false}
            archivedButton={false}
            withPageHeader={false}
            showAction={false}
            editAction={false}
            deleteAction={false}
            onClickAdd={openModal}
            forceRefresh={forceRefresh}
            extraButtons={
              <Switch
                onChange={() => setIsCredit(!isCredit)}
                checkedChildren={t(`payments.tags.CLASSIC`)}
                unCheckedChildren={t(`payments.tags.CREDIT`)}
                style={{ marginRight: '20px' }}
              />
            }
            extraFilters={
              <Row>
                <RangePicker
                  picker="month"
                  value={filterDate}
                  style={{ marginLeft: '20px' }}
                  onChange={handleRangeChange}
                  placeholder={[
                    t('payments.placeholder.start_month'),
                    t('payments.placeholder.end_month')
                  ]}
                />
                <Select
                  style={{ width: '200px', marginLeft: 20 }}
                  showSearch
                  allowClear
                  loading={isLoading}
                  placeholder={t('payments.placeholder.end_month')}
                  filterOption={(input, option) => onsearch(input, option)}
                  onSelect={(value) => {
                    setSelectedCustomer(value);
                  }}
                  onClear={() => {
                    setSelectedCustomer(null);
                  }}
                >
                  {(customers || [])?.map((customer) => (
                    <Option key={customer._id} value={customer._id}>
                      {customer?.company_name}
                    </Option>
                  ))}
                </Select>
              </Row>
            }
          />
        </Spin>
      </Col>
    </>
  );
};

ListPayments.propTypes = {
  quittance: PropTypes.shape({
    _id: PropTypes.string
  })
};

ListPayments.defaultProps = {
  quittance: undefined
};
