import { Select, Form, DatePicker, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import usePatientContext from '../PatientContext';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field caregiver for patient data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useRacFields = () => {
  const { t } = useTranslation();
  const { enums } = usePatientContext();

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return [
    {
      name: ['reminders_prescription'],
      label: '',
      input: (
        <Form.List name="reminders_prescription" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(
                (field, index) =>
                  index < 3 && (
                    <div key={field.name}>
                      <Form.Item
                        {...field}
                        key={[field.name, 'type']}
                        name={[field.name, 'type']}
                        label={t('patients.form.reminders.type')}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            onsearch(input, option)
                          }
                        >
                          {(enums.remindersType || []).map((r) => (
                            <Option key={r} value={r}>
                              {t(`patients.enums.${r}`)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <CloseCircleOutlined
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '16px',
                          color: 'red',
                          cursor: 'pointer'
                        }}
                        onClick={() => remove(field.name)}
                      />
                      <Form.Item
                        {...field}
                        key={[field.name, 'date']}
                        name={[field.name, 'date']}
                        label={t('patients.form.reminders.date')}
                      >
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </div>
                  )
              )}
              {fields.length < 3 && (
                <Form.Item>
                  <Button type="primary" onClick={() => add()}>
                    {t('patients.form.reminders.addReminder')}
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      )
    }
  ];
};

export default useRacFields;
