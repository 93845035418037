import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

export const useFields = (payingagency) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [Enums, setEnums] = useState({});
  const { t } = useTranslation();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/contacts/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading}>
          {(Enums?.types || []).map((type) => (
            <Select.Option key={type} value={type}>
              {t(`enums.${type}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select loading={isLoading}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email']
    },
    {
      name: ['paying_agency'],
      hidden: true,
      initialValue: payingagency
    }
  ];

  return {
    fields,
    isLoading
  };
};
