import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { centerType } from '../../utils/constants/tagColors';

/**
 * Custom hook for generating content for a list based on provided data.
 * @param {Object} data - Object containing data for generating list content.
 * @param {string} data.soldto - The soldto value.
 * @param {string} data.type - The type value.
 * @param {string} data.finess_number - The finess number value.
 * @param {string} data.center_name - The center name value.
 * @param {Object} data.address - The address object containing city, street, and postal code.
 * @param {string} data.address.city - The city value in the address.
 * @param {string} data.address.street - The street value in the address.
 * @param {string} data.address.number - The number value in the address.
 * @param {string} data.address.postal_code - The postal code value in the address.
 * @returns {Array} - Array containing objects with label, span, and content for each list item.
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'centers.form';
  const { soldto, type, finess_number, center_name, address } = data;

  return [
    {
      label: t(`${translationKey}.center_name`),
      span: 1,
      content: center_name || '-'
    },
    {
      label: t(`${translationKey}.soldto`),
      span: 1,
      content: soldto || '-'
    },
    {
      label: t(`${translationKey}.finess_number`),
      span: 1,
      content: finess_number || '-'
    },
    {
      label: t(`${translationKey}.type`),
      span: 2,
      content: type ? t(`${translationKey}.enums.${type}`) : '-'
    },
    {
      label: '',
      span: 1,
      content:
        (type && (
          <Tag color={centerType[type]}>{t(`centers.tags.${type}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: t(`${translationKey}.address.city`),
      span: 1,
      content: address?.city || '-'
    },
    {
      label: t(`${translationKey}.address.street`),
      span: 1,
      content:
        address && address?.street && address?.number
          ? `${address.number}, ${address.street}`
          : '-'
    },
    {
      label: t(`${translationKey}.address.postal_code`),
      span: 1,
      content: address?.postal_code || '-'
    }
  ];
};
