import { useTranslation } from 'react-i18next';

export const useColumnsCenter = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('centers.form.soldto'),
      key: 'soldto',
      dataIndex: 'soldto',
      sorter: true
    },
    {
      title: t('centers.form.center_name'),
      key: 'center_name',
      dataIndex: 'center_name',
      sorter: true
    }
  ];
};
