import { Select, Radio, Form, DatePicker, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import usePatientContext from '../PatientContext';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field invoicing for patient data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useRemindersInsuranceFields = () => {
  const { t } = useTranslation();
  const {
    enums = {},
    payingAgency,
    isSwitchAMC,
    setIsSwitchAMC
  } = usePatientContext();

  const onsearch = (input, option) => {
    if (Array.isArray(option.children)) {
      const childrenString = option.children.join(' ');
      return childrenString.toLowerCase().includes(input.toLowerCase());
    }
    if (typeof option.children === 'string') {
      const result = option.children.toLowerCase();
      return result.includes(input.toLowerCase());
    }
    return false;
  };

  return [
    {
      name: ['insurance'],
      initialValue: isSwitchAMC,
      rules: [{ required: true }],
      input: (
        <Radio.Group
          onChange={(event) => setIsSwitchAMC(event.target.value)}
          options={[true, false].map((b) => ({
            label: t(`switch.${b}`),
            value: b
          }))}
        />
      )
    },
    ...(isSwitchAMC
      ? [
          {
            name: ['payingagency_amc'],
            input: (
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {payingAgency
                  .filter(
                    (agency) =>
                      agency.type === 'AMC' || agency.type === 'AMC_NO'
                  )
                  .map((agency) => (
                    <Option key={agency._id} value={agency._id}>
                      {agency.name_agency} / {agency.transmission_code}
                    </Option>
                  ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['reminders_insurance_card'],
      input: (
        <Form.List name="reminders_insurance_card" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(
                (field, index) =>
                  index < 3 && (
                    <div key={field.name}>
                      <Form.Item
                        {...field}
                        key={[field.name, 'type']}
                        name={[field.name, 'type']}
                        label={t('patients.form.reminders.type')}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            onsearch(input, option)
                          }
                        >
                          {(enums.remindersType || []).map((r) => (
                            <Option key={r} value={r}>
                              {t(`patients.enums.${r}`)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <CloseCircleOutlined
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '16px',
                          color: 'red',
                          cursor: 'pointer'
                        }}
                        onClick={() => remove(field.name)}
                      />
                      <Form.Item
                        {...field}
                        key={[field.name, 'date']}
                        name={[field.name, 'date']}
                        label={t('patients.form.reminders.date')}
                      >
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </div>
                  )
              )}
              {fields.length < 3 && (
                <Form.Item>
                  <Button type="primary" onClick={() => add()}>
                    {t('patients.form.reminders.addReminder')}
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      )
    }
  ];
};

export default useRemindersInsuranceFields;
