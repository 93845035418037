import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('payingagencies.form.name_agency'),
      key: 'name_agency',
      dataIndex: 'name_agency',
      sorter: true
    },
    {
      title: t('payingagencies.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => t(`enums.${type}`) || '-',
      sorter: true
    },
    {
      title: t('payingagencies.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number),
      sorter: true
    },
    {
      title: t('payingagencies.form.transmission_code'),
      key: 'transmission_code',
      dataIndex: 'transmission_code',
      sorter: true
    }
  ];
};
