export const headers = [
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'phone_number.country_code',
    key: 'phone_number.country_code'
  },
  {
    label: 'phone_number.number',
    key: 'phone_number.number'
  },
  {
    label: 'role',
    key: 'role'
  },
  {
    label: 'postal_code_center',
    key: 'postal_code'
  },
  {
    label: 'center_name',
    key: 'center_name'
  },
  {
    label: 'soldto',
    key: 'soldto'
  },
  {
    label: 'address.country',
    key: 'address.country'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.additional',
    key: 'address.additional'
  },
  {
    label: 'address.postcode',
    key: 'address.postcode'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.country',
    key: 'address.country'
  }
];
