/**
 * Retrieves enums data from the server.
 * @param {Object} dispatchAPI - The dispatchAPI function from the AuthContext.
 * @returns {Object|Error} - The enums data or an error object.
 */
export const getEnums = async (dispatchAPI) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/contacts/enums'
    });
    return data;
  } catch (e) {
    return e; // Renvoie l'erreur directement
  }
};
