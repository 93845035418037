import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const PatientContext = createContext({});

/**
 * A context provider component for managing patient-related data and actions.
 *
 * @context
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider
 * @returns {ReactNode} The rendered component
 */
export const PatientContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [payingAgency, setPayingAgency] = useState([]);
  const [selectedPayingAgency, setSelectedPayingAgency] = useState(null);
  const [selectedCommunicatorModel, setSelectedCommunicatorModel] =
    useState(null);
  const [selectedCaregiverRole, setSelectedCaregiverRole] = useState(null);
  const [isSwitchInsurranceFile, setIsSwitchInsurranceFile] = useState(false);
  const [isSwitchAMC, setIsSwitchAMC] = useState(false);
  const [healthInsurance, setHealthInsurance] = useState([]);
  const [prescribers, setPrescribers] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [model, setModel] = useState('');

  const openDrawer = (modelType) => {
    setModel(modelType);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setModel('');
    setIsDrawerOpen(false);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/patients/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getPrescribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers' });

      setPrescribers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPayingAgency = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/payingagency'
      });
      setPayingAgency(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await getEnums();
    await getPayingAgency();
    await getPrescribers();
  }, []);

  const uploadOrDeleteFile = async (file, id, type, key) => {
    setIsFileLoading(true);
    const formData = new FormData();
    if (type !== 'delete') formData.append(`${key}`, file);
    formData.append('values', JSON.stringify({ ...formValues }));
    try {
      await dispatchAPI('PATCH', {
        url: `/patients/file/${id}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
    setIsFileLoading(false);
  };

  return (
    <PatientContext.Provider
      value={{
        enums,
        payingAgency,
        setSelectedPayingAgency,
        setSelectedCommunicatorModel,
        setSelectedCaregiverRole,
        isSwitchInsurranceFile,
        isSwitchAMC,
        setIsSwitchInsurranceFile,
        setIsSwitchAMC,
        uploadOrDeleteFile,
        healthInsurance,
        setHealthInsurance,
        isFileLoading,
        selectedPayingAgency,
        selectedCaregiverRole,
        selectedCommunicatorModel,
        setFormValues,
        formValues,
        forceRefresh,
        isDrawerOpen,
        model,
        openDrawer,
        setIsDrawerOpen,
        closeDrawer,
        getPayingAgency,
        prescribers,
        getPrescribers
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

PatientContext.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(PatientContext);
