import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Skeleton, Card, Button } from 'antd';
import { PageHeaderCustom, ListResource } from '../../components';

/**
 * Represents a custom card section containing a resource list. This component
 * uses a skeleton screen as a placeholder during loading and supports various
 * customizations including a button and specific queries for resource listing.
 *
 * @param {Object} props Component props
 * @param {boolean} props.isLoading Indicates if the component is in the loading state.
 * @param {string} props.type Type of card, used for title localization and button text.
 * @param {Function} props.buttonClick Callback function to execute when the button is clicked.
 * @param {string} props.extraQuery Additional query for the list resource component.
 * @param {Array} props.columns Columns configuration for the list of resources. Each column config
 *                              includes a title, dataIndex, key, and an optional render function.
 * @param {string} props.resourceName Name of the resource to display.
 * @returns {JSX.Element} The CustomCardSection component.
 */
const CustomCardSection = ({
  isLoading,
  type,
  buttonClick,
  extraQuery,
  columns,
  resourceName
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 24]} style={{ marginBottom: '20px' }}>
      <Col xs={24}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Card>
            <PageHeaderCustom
              title={
                <span className="title-card">
                  {t(`centers.show.titles.${type}`)}
                </span>
              }
              withoutBreadCrums
              withoutNavigateBack
            />
            {buttonClick && (
              <Button type="primary" onClick={buttonClick}>
                {t(`centers.form.buttonAddContact.create.${type}`)}
              </Button>
            )}
            <ListResource
              resourceName={resourceName}
              columns={columns}
              resourceModelName={resourceName}
              extraQuery={extraQuery}
              withPageHeader={false}
              withCreateButton={false}
              withSearchBar={false}
              showAction={false}
              editAction={false}
              deleteAction={false}
              onDoubleClickAction={false}
            />
          </Card>
        </Skeleton>
      </Col>
    </Row>
  );
};

CustomCardSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  buttonClick: PropTypes.func,
  extraQuery: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func
    })
  ).isRequired,
  resourceName: PropTypes.string.isRequired
};

CustomCardSection.defaultProps = {
  buttonClick: null
};

export default CustomCardSection;
