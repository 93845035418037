import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Row, Col, Skeleton, Card } from 'antd';

export const PatientDetailCard = ({
  title,
  contentData,
  isLoading,
  renderExtraCard
}) => (
  <Row gutter={[0, 24]} style={{ marginBottom: '20px' }}>
    <Col xs={24}>
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <Card
          title={<span className="title-card">{title}</span>}
          extra={renderExtraCard && renderExtraCard()}
        >
          {contentData}
        </Card>
      </Skeleton>
    </Col>
  </Row>
);

PatientDetailCard.propTypes = {
  title: PropTypes.string.isRequired,
  contentData: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  renderExtraCard: PropTypes.func
};

PatientDetailCard.defaultProps = {
  renderExtraCard: null
};
