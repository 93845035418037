import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList,
  ListResource
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { useColumnsCenter } from './columnsCenters';
import { useColumnsPrescriptions } from './columnsPrescriptions';

/**
 * Component for displaying details of a prescriber.
 * @returns {JSX.Element} - JSX for displaying details of a prescriber.
 */
export const ShowPrescriber = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [prescriber, setPrescriber] = useState();
  const [prescriptions, setPrescriptions] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const listContent = useListContent(prescriber);
  const columnsCenter = useColumnsCenter();
  const columnsPrescriptions = useColumnsPrescriptions(patientDetails);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();

  const getPrescriptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescriptionrequest?populate=patient`
      });

      const tempPatientDetails = [];

      data.forEach(async (prescription) => {
        const { patient } = prescription;
        if (patient && patient._id) {
          try {
            const { data: patientData } = await dispatchAPI('GET', {
              url: `/patients/${patient._id}`
            });
            tempPatientDetails.push(patientData);
          } catch (e) {
            if (e.response) message(e.response.status);
          }
        }
      });

      setPatientDetails(tempPatientDetails);
      setPrescriptions(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getPrescriber = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescribers/${id}?populate=comments.author`
      });
      setPrescriber(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deletePrescriber = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/prescribers/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/prescribers/comments/${id}`,
        body
      });
      form.resetFields();
      getPrescriber();
    } catch (e) {
      message(e);
    }
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getPrescriber();
      await getPrescriptions();
    })();
  }, [getPrescriber, getPrescriptions]);

  return (
    <>
      <PageHeaderCustom
        title={t('prescribers.show.title')}
        extra={
          <>
            <Button type="primary" onClick={showDrawer}>
              {`${t('prescribers.addCommentButton')} `}
            </Button>
            <Link to={{ pathname: `${routes.PRESCRIBERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deletePrescriber}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <DescriptionList data={listContent} translate />
              </Card>
            </Skeleton>
          </Col>
        </Row>
        <Row gutter={[0, 24]}>
          <Col xs={12}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <PageHeaderCustom
                  title={
                    <span className="title-card">
                      {t('prescribers.title_center')}
                    </span>
                  }
                  withoutBreadCrums
                  withoutNavigateBack
                />
                <ListResource
                  resourceName="centers"
                  columns={columnsCenter}
                  resourceModelName="Centers"
                  withPageHeader={false}
                  withCreateButton={false}
                  withSearchBar={false}
                  showAction={false}
                  deleteAction={false}
                  editAction={false}
                  populate="prescribers"
                  extraQuery={`prescribers=${id}`}
                />
              </Card>
            </Skeleton>
          </Col>
          <Col xs={12}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <PageHeaderCustom
                  title={
                    <span className="title-card">
                      {t('prescribers.title_prescriptions_request')}
                    </span>
                  }
                  withoutBreadCrums
                  withoutNavigateBack
                />
                <Button type="primary">
                  {t('prescribers.button_add_prescription')}
                </Button>
                <ListResource
                  resourceName="prescriptionrequest"
                  columns={columnsPrescriptions}
                  data={prescriptions}
                  withPageHeader={false}
                  withCreateButton={false}
                  withSearchBar={false}
                  showAction={false}
                  deleteAction={false}
                  editAction={false}
                  populate="prescriber,patient"
                  extraQuery={`prescriber=${id}`}
                />
              </Card>
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
      <CommentsDrawer
        title={t('prescribers.drawer.addComment.title')}
        onClose={onClose}
        open={open}
        size={size}
        updateResource={updateResource}
        form={form}
        models={prescriber}
      />
    </>
  );
};
