/**
 * Get key by value from object
 *
 * @function
 * @param {object} object - Object to search
 * @param {string} value - Value to search
 * @returns {string} Key of the value
 */
export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);
