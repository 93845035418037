import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../prescribers/fields';

/**
 * PrescriptionPrescriberDrawer Component
 *
 * This component renders a drawer used for creating or updating prescribers.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isDrawerOpen - Flag indicating if the drawer is open.
 * @param {string} props.purpose - The purpose of the drawer, used to determine the fields and title.
 * @param {Function} props.closeDrawer - Function to close the drawer.
 * @param {Function} props.getPrescribers - Function to fetch the list of prescribers after closing the drawer.
 * @param {Array} props.centers - Array of centers used to populate the fields.
 *
 * @returns {JSX.Element} The rendered component.
 */
const PrescriptionPrescriberDrawer = ({
  isDrawerOpen,
  purpose,
  closeDrawer,
  getPrescribers,
  centers
}) => {
  const { t } = useTranslation();

  const { fields } = useFields(purpose, centers);

  const customNavigate = () => {
    closeDrawer();
    getPrescribers();
  };

  return (
    <Drawer
      centered
      title={t(`prescribers.titles.${purpose}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={1200}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        purpose={purpose}
        baseUrl="prescribers"
        resource="prescribers"
        isOverlay
        customNavigate={customNavigate}
        withHeader={false}
        withFilesManager={false}
      />
    </Drawer>
  );
};

PrescriptionPrescriberDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  purpose: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
  getPrescribers: PropTypes.func,
  centers: PropTypes.arrayOf(PropTypes.shape({}))
};

PrescriptionPrescriberDrawer.defaultProps = {
  purpose: null,
  getPrescribers: null,
  centers: []
};

export default PrescriptionPrescriberDrawer;
