import { getPhoneNumber } from '../../utils/formatters';

export const useListContent = (data = {}) => {
  const {
    first_name,
    last_name,
    health_insurance_id,
    rpps_number,
    email,
    phone_number,
    speciality,
    address
  } = data;

  return [
    {
      label: 'prescribers.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'prescribers.form.first_name',
      span: 1,
      content: first_name || '-'
    },
    {
      label: 'prescribers.form.speciality',
      span: 1,
      content: speciality || '-'
    },
    {
      label: 'prescribers.form.health_insurance_id',
      span: 1,
      content: health_insurance_id || '-'
    },
    {
      label: 'prescribers.form.address.city',
      span: 1,
      content: address?.city || '-'
    },
    {
      label: 'prescribers.form.address.street',
      span: 1,
      content:
        address && address?.street && address?.number
          ? `${address.number}, ${address.street}`
          : '-'
    },
    {
      label: 'prescribers.form.address.postcode',
      span: 1,
      content: address?.postal_code || '-'
    },
    {
      label: 'prescribers.form.rpps_number',
      span: 1,
      content: rpps_number || '-'
    },
    {
      label: 'prescribers.form.phone_number.number',
      span: 2,
      content:
        phone_number && phone_number.number ? getPhoneNumber(phone_number) : '-'
    },
    {
      label: 'prescribers.form.email',
      span: 1,
      content: email || '-'
    }
  ];
};
