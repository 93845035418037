import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import PrescriptionPrescriberDrawer from './PrescriptionPrescriberDrawer';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for creating or updating a .
 * @param {Object} props - The props object.
 * @param {string} props.purpose - The purpose of the operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} - JSX for creating or updating a prescription.
 */
export const CreateUpdatePrescription = ({ purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [patients, setPatients] = useState([]);
  const [prescribers, setPrescribers] = useState([]);
  const [centers, setCenters] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const getPrescribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers' });
      setPrescribers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPatients = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/patients' });
      setPatients(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCenters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers' });
      setCenters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const uploadOrDeleteFile = async (file, id_prescription, type, key) => {
    setIsLoading(true);
    const formData = new FormData();
    if (type !== 'delete') formData.append(`${key}`, file);
    formData.append('values', JSON.stringify({ ...formValues }));
    try {
      await dispatchAPI('PATCH', {
        url: `/prescriptions/file/${id_prescription}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
    } catch (e) {
      message(e);
    }
    if (type === 'delete') setFileList([]);
    setRefresh(!refresh);
    setIsLoading(false);
  };

  const { fields } = useFields(
    openDrawer,
    id,
    purpose,
    patients,
    centers,
    prescribers,
    setFileList,
    fileList,
    uploadOrDeleteFile,
    isLoading
  );

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append(
          'values',
          JSON.stringify({
            ...data,
            first_period_validity: {
              start_date: new Date(data.first_period_validity[0]),
              end_date: new Date(data.first_period_validity[1])
            },
            second_period_validity: {
              start_date: new Date(data.second_period_validity[0]),
              end_date: new Date(data.second_period_validity[1])
            }
          })
        );
        if (fileList) formData.append('prescription_file', fileList[0]);
        return formData;
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        first_period_validity: {
          start_date: new Date(data.first_period_validity[0]),
          end_date: new Date(data.first_period_validity[1])
        },
        second_period_validity: {
          start_date: new Date(data.second_period_validity[0]),
          end_date: new Date(data.second_period_validity[1])
        }
      })
    },
    onGetResource: {
      setFields: (data) => {
        if (data?.prescription_file) {
          setFileList([data?.prescription_file]);
        }
        return {
          ...data,
          first_period_validity: [
            dayjs(data?.first_period_validity?.start_date),
            dayjs(data?.first_period_validity?.end_date)
          ],
          second_period_validity: [
            dayjs(data?.second_period_validity?.start_date),
            dayjs(data?.second_period_validity?.end_date)
          ]
        };
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getPrescribers(), getCenters(), getPatients()]);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isDrawerOpen && (
        <PrescriptionPrescriberDrawer
          isDrawerOpen={isDrawerOpen}
          purpose="create"
          closeDrawer={closeDrawer}
          getPrescribers={getPrescribers}
          centers={centers}
        />
      )}
      <CreateUpdateContainer
        config={config}
        fields={fields}
        purpose={purpose}
        baseUrl="prescriptions"
        resource="prescriptions"
        populate="prescription_file"
        withFilesManager={false}
        isFormData={purpose === 'create'}
        fileList={fileList}
        needFormDependencies
        setFormValues={setFormValues}
        refresh={refresh}
      />
    </>
  );
};

CreateUpdatePrescription.propTypes = {
  purpose: PropTypes.string.isRequired
};
