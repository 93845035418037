import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../prescribers/fields';

const CenterPrescriberDrawer = ({
  isDrawerOpen,
  purpose,
  closeDrawer,
  getPrescribers,
  centers
}) => {
  const { t } = useTranslation();

  const { fields } = useFields(purpose, centers);

  const customNavigate = () => {
    closeDrawer();
    getPrescribers();
  };

  return (
    <Drawer
      centered
      title={t(`prescribers.titles.${purpose}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={1200}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        purpose={purpose}
        baseUrl="prescribers"
        resource="prescribers"
        isOverlay
        customNavigate={customNavigate}
        withHeader={false}
      />
    </Drawer>
  );
};

CenterPrescriberDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  purpose: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
  getPrescribers: PropTypes.func,
  centers: PropTypes.arrayOf(PropTypes.shape({}))
};

CenterPrescriberDrawer.defaultProps = {
  purpose: null,
  getPrescribers: null,
  centers: []
};

export default CenterPrescriberDrawer;
