import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

/**
 * Component for displaying a list of paying agencies.
 * @returns {JSX.Element} - JSX for displaying a list of paying agencies.
 */

export const ListPayingAgency = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="payingagencies"
      columns={columns}
      resourceModelName="payingagencies"
      headers={headers}
    />
  );
};
