import { useTranslation } from 'react-i18next';
import { getFullName } from '../../utils/formatters';

/**
 * useColumns Hook
 *
 * This hook returns an array of column definitions for a table displaying patient,
 * center, and prescriber information. Each column definition includes properties
 * for rendering the column title, key, data index, render function, and sorting capabilities.
 *
 * @returns {Array<Object>} The array of column definitions.
 *
 * @property {string} title - The translated title of the column.
 * @property {string} key - The key for the column, used for identifying the column.
 * @property {string} dataIndex - The data index for the column, used to retrieve the data for rendering.
 * @property {Function} render - Function to render the content of the column.
 * @property {boolean} sorter - Flag indicating if the column is sortable.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('patients.form.last_name'),
      key: 'patient',
      dataIndex: 'patient',
      render: (patient) => patient?.last_name || '-',
      sorter: true
    },
    {
      title: t('patients.form.first_name'),
      key: 'patient',
      dataIndex: 'patient',
      render: (patient) => patient?.first_name || '-',
      sorter: true
    },
    {
      title: t('patients.form.social_security_number'),
      key: 'patient',
      dataIndex: 'patient',
      render: (patient) => patient?.social_security_number || '-',
      sorter: true
    },
    {
      title: t('centers.form.center_name'),
      key: 'center',
      dataIndex: 'center',
      render: (center) => center?.center_name || '-',
      sorter: true
    },
    {
      title: t('centers.form.finess_number'),
      key: 'center',
      dataIndex: 'center',
      render: (center) => center?.finess_number || '-',

      sorter: true
    },
    {
      title: t('prescribers.form.last_name'),
      key: 'prescriber',
      dataIndex: 'prescriber',
      render: (prescriber) => getFullName(prescriber)
    },
    {
      title: t('prescribers.form.rpps_number'),
      key: 'prescriber',
      dataIndex: 'prescriber',
      render: (prescriber) => prescriber?.rpps_number || '-',
      sorter: true
    }
  ];
};
