import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getPhoneNumber } from '../../utils/formatters';

export const useColumns = (openDrawer) => {
  const { t } = useTranslation();

  return [
    {
      title: t('payingagencies.form.contacts.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => t(`enums.${type}`) || '-',
      sorter: true
    },
    {
      title: t('payingagencies.form.contacts.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('payingagencies.form.contacts.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('payingagencies.form.contacts.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number),
      sorter: true
    },
    {
      title: t('payingagencies.form.contacts.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Button
          type="ghost"
          onClick={() => openDrawer(record._id, record?.type)}
          icon={<EditOutlined style={{ fontSize: 18 }} />}
        />
      )
    }
  ];
};
