import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  MailOutlined,
  IdcardOutlined,
  SolutionOutlined,
  BankOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  PATIENTS: <IdcardOutlined />,
  PRESCRIPTIONS: <SolutionOutlined />,
  INVOICES: <SolutionOutlined />,
  PAYMENTS: <SolutionOutlined />,
  PRESCRIBERS: <SolutionOutlined />,
  CENTERS: <HomeOutlined />,
  PAYING_AGENCIES: <BankOutlined />,
  STATISTICS: <BankOutlined />,
  USERS: <UserOutlined />,
  MAILS: <MailOutlined />,
  SETTINGS: <SettingOutlined />
};

export default navMenuLogos;
