import dayjs from 'dayjs';

/**
 * Returns the formatted address string by combining the address components.
 *
 * @function
 * @param {Object} address - The address object.
 * @param {string} address.number - The street number. (optional) (ex. '12')
 * @param {string} address.street - The street name. (required) (ex. 'Rue de la Paix')
 * @param {string} address.additional - Additional address information (optional). (ex. 'Bâtiment A')
 * @param {string} address.postal_code - The postal code. (required) (ex. '75000')
 * @param {string} address.city - The city. (required) (ex. 'Paris')
 * @returns {string} The formatted address string. (ex. '12, Rue de la Paix, Bâtiment A, 75000 Paris') or '-' if no address is provided.
 */
export const getAddress = (address) => {
  if (!address || typeof address !== 'object') {
    return '-';
  }
  const { number, street, additional, postal_code, city } = address;
  if (!street || !postal_code || !city) return '-';

  const addressString = `${number ? `${number}, ` : ''}${street}, ${
    additional ? `${additional} ,` : ''
  }${postal_code} ${city}`;

  return addressString.trim();
};

/**
 * Returns the formatted phone number by concatenating the country code and number.
 *
 * @function
 * @param {Object} phoneNumber - The phone number object. (ex. { country_code: '+33', number: '0612345678' })
 * @param {string} phoneNumber.country_code - The country code. (ex. '+33')
 * @param {string} phoneNumber.number - The phone number. (ex. '0612345678')
 * @returns {string} The formatted phone number. (ex. '+33 6 12 34 56 78') or '-' if no phone number is provided.
 */
export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== 'object') return '-';
  const { country_code = null, number = null } = phoneNumber;
  if (!country_code || !number) return '-';

  let modifiedNumber;
  let formattedNumber;

  switch (country_code) {
    case '+33':
      // Remove the first digit of the number to return '6 12 34 56 78' format.
      modifiedNumber = number.slice(1);
      formattedNumber = modifiedNumber.replace(
        /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1 $2 $3 $4 $5'
      );
      return `${country_code} ${formattedNumber}`;
    default:
      return '-';
  }
};

/**
 * Returns the full name by concatenating the first name and last name.
 *
 * @function
 * @param {Object} person - The person object. (ex. { first_name: 'John', last_name: 'Doe' })
 * @returns {string} The full name. (ex. 'John Doe') or '-' if no name is provided.
 */
export const getFullName = (person) => {
  if (!person) return '-';
  const { first_name, last_name } = person;
  if (!first_name && !last_name) return '-';
  return `${first_name ? `${first_name} ` : ''}${last_name || ''}`;
};

/**
 * Returns the formatted birth date.
 *
 * @function
 * @param {string} date - The birth date. (ex. '1995-11-03T23:00:00.000Z')
 * @returns {string} The formatted birth date. (ex. '04-11-1995') or '-' if no date is provided.
 */
export const getDate = (date) => {
  if (!date) return '-';
  return dayjs(date).format('DD-MM-YYYY');
};

/**
 * Calculates the age based on the given birth date and returns the age.
 *
 * @function
 * @param {string} birth_date - The birth date. (ex: '1955-06-14T23:00:00.000Z')
 * @returns {number} The age in years. (ex: 68 ans)
 */
export const getAge = (birth_date) => {
  const birthDate = dayjs(birth_date);
  const today = dayjs();

  return today.diff(birthDate, 'years');
};

/**
 * Returns the formatted insurance number by adding a space every two digits.
 *
 * @function
 * @param {string} insNum - The insurance number. (ex. '1234567890123456')
 * @returns {string} The formatted insurance number. (ex. '1234 5678 9012 3456') or '-' if no insurance number is provided.
 */
export const getInsNumber = (insNum) => {
  if (!insNum) return '-';
  return insNum.replace(/(\d)(?=(\d{2})+(?!\d))/g, '$1 ');
};

/**
 * Returns the formatted visit reasons by joining the reason wordings with a comma.
 *
 * @function
 * @param {Array} visitReasons - The array of visit reasons. (ex. [{ reason_wording: 'Consultation' }, { reason_wording: 'Vaccination' }])
 * @returns {string} The formatted visit reasons string. (ex. 'Consultation, Vaccination') or '-' if no visit reasons are provided.
 */
export const getVisitReasons = (visitReasons) => {
  if (!visitReasons || visitReasons?.length === 0) return '-';
  return visitReasons?.map((reason) => reason?.reason_wording).join(', ');
};

/**
 * Gets the formatted date picker format.
 * @returns {string} - The date picker format.
 */
export const getFormattedDatePicker = () => 'DD/MM/YYYY';
