import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { userRoles, userEmployers } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: ['role'],
      render: (role) =>
        role ? (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.roles?.map((r) => ({
        text: t(`users.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('users.form.employer'),
      key: 'employer',
      dataIndex: ['employer'],
      render: (employer) =>
        employer ? (
          <Tag color={userEmployers[employer]}>
            {t(`users.tags.${employer}`, {
              defaultValue: employer
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.employers?.map((r) => ({
        text: t(`users.tags.${r}`),
        value: r
      }))
    }
  ];
};
