import { getFullName } from '../../utils/formatters';
/**
 * useListContent Hook
 *
 * This hook returns an array of content definitions for displaying details
 * of a prescription, including patient, center, and prescriber information.
 *
 * @param {Object} data - The data object containing patient, center, and prescriber details.
 * @param {Object} data.patient - The patient object containing patient details.
 * @param {string} [data.patient.last_name] - The last name of the patient.
 * @param {string} [data.patient.first_name] - The first name of the patient.
 * @param {Object} data.center - The center object containing center details.
 * @param {string} [data.center.center_name] - The name of the center.
 * @param {Object} data.prescriber - The prescriber object containing prescriber details.
 * @param {string} [data.prescriber.last_name] - The last name of the prescriber.
 * @param {string} [data.prescriber.first_name] - The first name of the prescriber.
 *
 * @returns {Array<Object>} The array of content definitions.
 * @returns {string} content.label - The label key for translation.
 * @returns {number} content.span - The span value for layout.
 * @returns {string} content.content - The content to be displayed.
 */
export const useListContent = (data = {}) => {
  const { patient, center, prescriber } = data;

  return [
    {
      label: 'prescriptions.form.patient',
      span: 1,
      content: getFullName(patient)
    },
    {
      label: 'prescriptions.form.center',
      span: 1,
      content: center?.center_name || '-'
    },
    {
      label: 'prescriptions.form.prescriber',
      span: 1,
      content: getFullName(prescriber)
    }
  ];
};
