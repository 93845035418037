import PropTypes from 'prop-types';
import { useState } from 'react';
import { usePayingAgencyFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Component for creating or updating a paying agency.
 * @param {Object} props - The props object.
 * @param {string} props.purpose - The purpose of the operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} - JSX for creating or updating a paying agency.
 */

export const CreateUpdatePayingAgency = ({ purpose }) => {
  const [procedure, setProcedure] = useState({});
  const { payingAgencyfields } = usePayingAgencyFields(
    purpose,
    procedure,
    setProcedure
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setProcedure(data?.follow_up_prodcedure);
        return {
          ...data,
          address:
            data?.follow_up_prodcedure === 'COURRIER_POSTAL'
              ? data?.address
              : {},
          procedure_url:
            data?.follow_up_prodcedure === 'FORMULAIRE_EN_LIGNE'
              ? data?.procedure_url
              : null,
          procedure_email:
            data?.follow_up_prodcedure === 'MAIL' ? data?.procedure_email : null
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        address:
          data?.follow_up_prodcedure === 'COURRIER_POSTAL' ? data?.address : {},
        procedure_url:
          data?.follow_up_prodcedure === 'FORMULAIRE_EN_LIGNE'
            ? data?.procedure_url
            : null,
        procedure_email:
          data?.follow_up_prodcedure === 'MAIL' ? data?.procedure_email : null
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        address:
          data?.follow_up_prodcedure === 'COURRIER_POSTAL' ? data?.address : {},
        procedure_url:
          data?.follow_up_prodcedure === 'FORMULAIRE_EN_LIGNE'
            ? data?.procedure_url
            : null,
        procedure_email:
          data?.follow_up_prodcedure === 'MAIL' ? data?.procedure_email : null
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={payingAgencyfields}
      purpose={purpose}
      baseUrl="payingagency"
      resource="payingagencies"
      config={config}
      withFilesManager={false}
    />
  );
};

CreateUpdatePayingAgency.propTypes = {
  purpose: PropTypes.string.isRequired
};
