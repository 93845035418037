import PropTypes from 'prop-types';
import moment from 'moment';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for creating or updating a user.
 * @param {Object} props - The props object.
 * @param {string} props.purpose - The purpose of the form (e.g., 'create', 'update').
 * @returns {JSX.Element} - JSX for creating or updating a user.
 */

export const CreateUpdateUser = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields(purpose);
  const { user, setUser } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        return {
          ...data
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};
