import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Dropdown, Input, Flex } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { AddIcon } from '../../utils/constants/customIcons';
import { pathSearches, routes } from '../../utils/constants/adminRoutes';
import { getKeyByValue } from '../../utils/objects';

const { Search } = Input;

export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  setTotals,
  withImportButton,
  useUrlFilter
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [stateSearch, setStateSearch] = useState(
    pathSearches[getKeyByValue(routes, pathname)]
  );
  const params = new URLSearchParams(stateSearch || location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const searchResource = (value) => {
    const search = `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
      currentSorter ? `&s=${currentSorter}` : ''
    }${currentFilters ? `&f=${currentFilters}` : ''}${
      value ? `&k=${encodeURIComponent(value)}` : ''
    }`;
    if (useUrlFilter) {
      navigate({ pathname, search });
    } else {
      setStateSearch(search);
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                />
              )
            }
          ]
        : []),
      ...(withImportButton
        ? [
            {
              key: 'import',
              label: <ImportButton resourceName={resourceModelName} />
            }
          ]
        : [])
    ]
  };

  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        <Flex
          justify={withSearchBar ? 'space-between' : 'end'}
          gap="small"
          align="center"
          wrap="wrap"
        >
          {withSearchBar && (
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
              style={{ minWidth: 190, maxWidth: 300 }}
            />
          )}
          {!withCreateButton && headers && (
            <Dropdown menu={menu}>
              <Button type="link">
                <MenuOutlined
                  style={{ fontSize: 16, color: 'var(--textColor)' }}
                />
              </Button>
            </Dropdown>
          )}
          {withCreateButton && (
            <Flex align="center">
              {extraButtons}
              <Link to={withCreateButton?.path || `${pathname}/create`}>
                <Button type="add">
                  {withCreateButton?.buttonText || `${t('buttons.create')}`}
                  &nbsp;
                  {withCreateButton?.buttonIcon || <AddIcon />}
                </Button>
              </Link>
              {withUploadButton && (
                <Dropdown menu={menu}>
                  <Button type="link">
                    <MenuOutlined
                      style={{ fontSize: 16, color: 'var(--textColor)' }}
                    />
                  </Button>
                </Dropdown>
              )}
            </Flex>
          )}
        </Flex>
        <Flex gap="small" align="center">
          {children}
        </Flex>
        <Datatable
          style={{ marginTop: 16 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          duplicateAction={duplicateAction}
          printAction={printAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          setTotals={setTotals}
          useUrlFilter={useUrlFilter}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withImportButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  setTotals: PropTypes.func,
  useUrlFilter: PropTypes.bool
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  withImportButton: false,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  setTotals: null,
  useUrlFilter: true
};
