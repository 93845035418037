import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import usePatientContext from './PatientContext';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import usePatientFields from './fields/patientFields';
import useCaregiverFields from './fields/caregiverFields';
import usePayingAgencyFields from './fields/payingAgencyFields';
import useRemindersInsuranceFields from './fields/remindersInsuranceFields';
import useRemindersPrescriptionFields from './fields/remindersPrescriptionFields';

/**
 * CreateUpdatePatient is a React component that provides a form for creating or updating patient data.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'edit'.
 * @returns {React.ReactNode} The rendered JSX for the CreateUpdatePatient component.
 */

export const CreateUpdatePatient = ({ purpose }) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    enums,
    setIsSwitchAMC,
    setHealthInsurance,
    setSelectedPayingAgency,
    setSelectedCommunicatorModel,
    setSelectedCaregiverRole,
    setIsDrawerOpen,
    healthInsurance
  } = usePatientContext();
  const patientFields = usePatientFields(purpose);
  const caregiverFields = useCaregiverFields();
  const payingAgencyFields = usePayingAgencyFields(purpose, id);
  const remindersInsuranceFields = useRemindersInsuranceFields(purpose, id);
  const remindersPrescriptionFields = useRemindersPrescriptionFields();
  const [activeKey, setActiveKey] = useState('1');
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setIsSwitchAMC(data?.insurance);
        setSelectedPayingAgency(
          data?.payingagency_amo
            ? data?.payingagency_amo
            : data?.payingagency_amc
        );
        setSelectedCaregiverRole(data?.caregiver_role);
        setSelectedCommunicatorModel(data?.communicator_model);
        setHealthInsurance([data?.health_insurance] || []);
        return {
          ...data,
          amc_card_validity: [
            dayjs(data?.amc_card_validity?.start_date),
            dayjs(data?.amc_card_validity?.end_date)
          ],
          reminders_prescription: (data?.reminders_prescription || []).map(
            (reminder) => ({
              ...reminder,
              date: reminder?.date && dayjs(reminder?.date)
            })
          ),
          reminders_insurance_card: (data?.reminders_insurance_card || []).map(
            (reminder) => ({
              ...reminder,
              date: reminder?.date && dayjs(reminder?.date)
            })
          ),
          communicator_activation_date:
            data?.communicator_activation_date &&
            dayjs(data?.communicator_activation_date),
          communicator_last_activity_date:
            data?.communicator_last_activity_date &&
            dayjs(data?.communicator_last_activity_date),
          created_at: data?.created_at && dayjs(data?.created_at),
          date_of_birth: data?.date_of_birth && dayjs(data?.date_of_birth)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        amc_card_validity: [
          dayjs(data?.amc_card_validity?.start_date),
          dayjs(data?.amc_card_validity?.end_date)
        ]
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        amc_card_validity: [
          dayjs(data?.amc_card_validity?.start_date),
          dayjs(data?.amc_card_validity?.end_date)
        ]
      })
    }
  };

  const tabs = [
    {
      label: t('patients.form.titles.patient'),
      key: '1',
      children: patientFields.map((field) => generateFields('patients', field))
    },
    {
      label: t('patients.form.titles.caregiver'),
      key: '2',
      children: caregiverFields.map((field) =>
        generateFields('patients', field)
      )
    },
    {
      label: t('patients.form.titles.payingagency'),
      key: '3',
      children: payingAgencyFields.map((field) =>
        generateFields('patients', field)
      )
    },
    {
      label: t('patients.form.titles.reminders_insurance'),
      key: '4',
      children: remindersInsuranceFields.map((field) =>
        generateFields('patients', field)
      )
    },
    {
      label: t('patients.form.titles.reminders_prescription'),
      key: '5',
      children: remindersPrescriptionFields.map((field) =>
        generateFields('patients', field)
      )
    }
  ];

  const errorValidationAction = (errors) => {
    if (errors?.errorFields?.[0]?.name?.[0]) {
      const fieldName = errors?.errorFields?.[0]?.name?.[0];
      const isFieldInPatient = patientFields.some((field) =>
        field.name.includes(fieldName)
      );
      const isFieldInPayingAgency = payingAgencyFields.some((field) =>
        field.name.includes(fieldName)
      );
      const isFieldInRemindersInsuranceFields = remindersInsuranceFields.some(
        (field) => field.name.includes(fieldName)
      );
      const isFieldInRemindersPrescription = remindersPrescriptionFields.some(
        (field) => field.name.includes(fieldName)
      );
      if (isFieldInRemindersPrescription) setActiveKey('5');
      if (isFieldInRemindersInsuranceFields) setActiveKey('4');
      if (isFieldInPayingAgency) setActiveKey('3');
      if (isFieldInPatient) setActiveKey('1');
    }
  };

  useEffect(() => {
    setSelectedPayingAgency(null);
    setSelectedCommunicatorModel(null);
    setSelectedCaregiverRole(null);
    setHealthInsurance([]);
    setIsSwitchAMC(false);
    setIsDrawerOpen(false);
    if (enums) setIsFieldsLoading(false);
  }, []);

  return (
    <CreateUpdateContainer
      tabs={tabs}
      purpose={purpose}
      baseUrl="patients"
      resource="patients"
      populate="health_insurance"
      config={config}
      loadingFields={isFieldsLoading}
      withFilesManager={false}
      errorValidationAction={errorValidationAction}
      activeKey={activeKey}
      setActiveKey={setActiveKey}
      isFormData={purpose === 'create'}
      fileInsurance={healthInsurance}
    />
  );
};

CreateUpdatePatient.propTypes = {
  purpose: propTypes.string.isRequired
};
