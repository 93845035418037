export const headers = [
  {
    label: 'soldto',
    key: 'soldto'
  },
  {
    label: 'nom du centre',
    key: 'center_name'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'numéro Finess',
    key: 'finess_number'
  },
  {
    label: 'n°',
    key: 'address.number'
  },
  {
    label: 'adresse',
    key: 'address.street'
  },
  {
    label: 'ville',
    key: 'address.city'
  },
  {
    label: 'code postal',
    key: 'address.postal_code'
  },
  {
    label: 'représentant commercial',
    key: 'sales_representative'
  },
  {
    label: 'directeur régional',
    key: 'regional_director'
  }
];
