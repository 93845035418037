import { createGlobalStyle } from 'styled-components';
import '../SCSS/admin/index.scss';

export const AdminTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #13277c;
    --addColor: #47d267;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: #ebeeff;
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  var(--secondaryColor);
    --secondaryColor: #617EFF;
    --disabledColor: #bfbfbf;
    --bodyBackground: #f0f2f5;
    --textColor: rgba(0, 0, 0, 0.65);
    --textColorSecondary: rgba(0, 0, 0, 0.45);
    --itemActiveBackground: #ebeeff;
    --itemHoverBackground: #f0f0f0;
    --borderColor: #d9d9d9;
    --contrastBackground: #f5f5f5;
    --errorColor: #e31919;
    --opacitySVG: 1;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
