import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Collapse, Tabs, Card } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import { formDependencies } from '../../utils/formDependencies';

/**
 * CreateUpdateContainer is a React component that provides a form for creating or updating a resource.
 *
 * @component
 * @param {string} purpose - The purpose of the form, either 'create' or 'edit'.
 * @param {Array<Object>} fields - An array of field configurations for the form.
 * @param {Array<string>} loadingFields - An array of field names to be loaded asynchronously.
 * @param {string} resource - The resource identifier.
 * @param {string} baseUrl - The base URL for API requests.
 * @param {Object} config - Configuration object for CRUD operations.
 * @param {React.ReactNode} formExtra - Extra content to be displayed within the form.
 * @param {string} tradKey - Key for translation purposes.
 * @param {string} submitLabel - Label for the submit button.
 * @param {Function} customSubmit - Custom submit function for the form.
 * @param {boolean} isParentLoading - Indicates if the parent component is loading.
 * @param {Array<Object>} categories - An array of category configurations.
 * @param {Function} setFormValues - Function to set form values externally.
 * @param {string} populate - Additional data to populate the form.
 * @param {Function} refresh - Function to trigger form refresh.
 * @param {boolean} isOverlay - Indicates if the form is displayed as an overlay.
 * @param {Function} customNavigate - Custom navigation function.
 * @param {string} idFromOverlay - Identifier used in overlay mode.
 * @param {boolean} withHeader - Indicates if a header should be displayed.
 * @param {Array<Object>} tabs - An array of tab configurations.
 * @returns {React.ReactNode} The rendered JSX for the CreateUpdateContainer component.
 * @param {Object} rejection - Object with include rejection.
 * @param {Function} errorValidationAction - Custom error action.
 * @param {string} activeKey - Active key for tabs.
 * @param {Function} setActiveKey - function to set the state activeKey.
 */

export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      categories,
      setFormValues,
      populate,
      refresh,
      isOverlay,
      customNavigate,
      idFromOverlay,
      withHeader,
      withCards,
      tabs,
      layout,
      rejection,
      needFormDependencies,
      errorValidationAction,
      activeKey,
      setActiveKey
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${idFromOverlay || id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        if (isOverlay) {
          customNavigate();
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        const { data } = await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        if (isOverlay) {
          customNavigate(data);
          setIsSubmitting(false);
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${idFromOverlay || id}?${
            populate ? `populate=${populate}` : ''
          }`
        });
        if (rejection && !rejection?.condition.includes(data[rejection.key])) {
          navigate(-1);
        }
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
        if (categories?.length || withCards?.length || needFormDependencies)
          setFormValues({
            ...(onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data)
          });
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose === 'edit' && (idFromOverlay || id)) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource, refresh, idFromOverlay]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    const handleFinishFailed = (errorInfo) => {
      if (errorValidationAction) {
        errorValidationAction(errorInfo);
      }
    };

    const onChangeActiveTab = (key) => {
      setActiveKey(key);
    };

    const handleChange = (values) => {
      if (categories?.length || withCards?.length || needFormDependencies) {
        const formData = form.getFieldsValue();
        setFormValues({ ...formData });
        formDependencies(
          dispatchAPI,
          message,
          values,
          formData,
          resource,
          form,
          setFormValues,
          categories?.map((category) => category.children)
        );
      }
    };

    return (
      <>
        {withHeader && (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        )}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              layout={layout}
              onFinish={handleSubmit}
              scrollToFirstError={{ block: 'center', behavior: 'smooth' }}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleChange}
              form={form}
            >
              {!tabs?.length &&
                !withCards?.length &&
                (categories?.length ? (
                  <Collapse
                    defaultActiveKey={['1']}
                    items={categories}
                    style={{ marginBottom: 16 }}
                  />
                ) : (
                  <>
                    {fields.map((field) =>
                      generateFields(tradKey || resource, field)
                    )}
                  </>
                ))}
              {withCards &&
                withCards.map((card) => (
                  <Card
                    bordered={card.bordered}
                    title={t(`${resource}.titles.${card.title}`)}
                  >
                    {card.fields.map((field) =>
                      generateFields(tradKey || resource, field)
                    )}
                  </Card>
                ))}
              {tabs?.length ? (
                <Tabs
                  defaultActiveKey={activeKey}
                  activeKey={activeKey}
                  items={tabs}
                  onChange={onChangeActiveTab}
                />
              ) : null}
              {formExtra}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 8px' }}
                    type="link"
                    danger
                    onClick={() =>
                      isOverlay ? customNavigate('cancel') : navigate(-1)
                    }
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button
                    type="add"
                    htmlType="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  setFormValues: PropTypes.func,
  isParentLoading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  populate: PropTypes.string,
  refresh: PropTypes.bool,
  isOverlay: PropTypes.bool,
  customNavigate: PropTypes.func,
  idFromOverlay: PropTypes.string,
  withHeader: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  layout: PropTypes.string,
  withCards: PropTypes.arrayOf(PropTypes.shape({})),
  rejection: PropTypes.shape({
    condition: PropTypes.arrayOf(PropTypes.string),
    key: PropTypes.string
  }),
  needFormDependencies: PropTypes.bool,
  errorValidationAction: PropTypes.func,
  activeKey: PropTypes.string,
  setActiveKey: PropTypes.func
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  setFormValues: null,
  isParentLoading: false,
  categories: null,
  populate: null,
  refresh: false,
  isOverlay: false,
  customNavigate: null,
  idFromOverlay: null,
  withHeader: true,
  tabs: [],
  withCards: [],
  layout: 'horizontal',
  rejection: null,
  needFormDependencies: false,
  errorValidationAction: null,
  activeKey: '1',
  setActiveKey: null
};
