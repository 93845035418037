import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { AddButton } from './AddButton';

/*
 * Please use the following format for the items prop when using subCollapse:
 * [{
 *  key: '0',
 *  label: 'Title',
 *  subCollapse: true,
 *  resourceName: 'typeps-macro',
 *  children: [
 *   {
 *    key: '0-1',
 *    label: 'Subtitle',
 *    resourceName: 'typeps-micro',
 *    children: <ReactComponent></ReactComponent>
 *   }]
 * }]
 */

export const CollapsableTable = ({
  items,
  addItem,
  defaultOpen,
  defaultOpenChildren,
  onOpen,
  onOpenChildren,
  accordion,
  subAccordion,
  formatToJsx,
  disableAddButtonCondition
}) => (
  <Collapse
    defaultActiveKey={defaultOpen}
    onChange={onOpen}
    items={items.map((item) => ({
      key: item.key,
      label: item.label,
      children: item.subCollapse ? (
        <>
          <CollapsableTable
            key={item.key}
            items={item.children}
            addItem={addItem}
            accordion={subAccordion}
            formatToJsx={formatToJsx}
            defaultOpen={defaultOpenChildren}
            onOpen={onOpenChildren}
          />
          {item.resourceName &&
            !disableAddButtonCondition(item.resourceName) && (
              <AddButton
                resourceName={item.resourceName}
                addItem={() => addItem(item.resourceName)}
              />
            )}
        </>
      ) : (
        <>
          {formatToJsx(item.children)}
          {(item.resourceName || item.subResourceName) &&
            !disableAddButtonCondition(item.resourceName) && (
              <AddButton
                resourceName={item.subResourceName || item.resourceName}
                addItem={() =>
                  addItem(item.subResourceName || item.resourceName, item)
                }
              />
            )}
        </>
      )
    }))}
    accordion={accordion}
  />
);

CollapsableTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      resourceName: PropTypes.string.isRequired,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape()),
        PropTypes.node
      ])
    })
  ).isRequired,
  addItem: PropTypes.func.isRequired,
  defaultOpen: PropTypes.arrayOf(PropTypes.string),
  defaultOpenChildren: PropTypes.arrayOf(PropTypes.string),
  onOpen: PropTypes.func,
  onOpenChildren: PropTypes.func,
  accordion: PropTypes.bool,
  subAccordion: PropTypes.bool,
  formatToJsx: PropTypes.func,
  disableAddButtonCondition: PropTypes.func
};

CollapsableTable.defaultProps = {
  defaultOpen: [],
  defaultOpenChildren: [],
  onOpen: () => null,
  onOpenChildren: () => null,
  accordion: false,
  subAccordion: false,
  formatToJsx: () => null,
  disableAddButtonCondition: () => false
};
