import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form, Input } from 'antd';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

export const usePayingAgencyFields = (purpose, procedure, setProcedure) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const [payingAgencyType, setPayingAgencyType] = useState(null);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/payingagency/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const payingAgencyfields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isLoading}
          onSelect={(value) => setPayingAgencyType(value)}
        >
          {(enums?.types || []).map((type) => (
            <Select.Option key={type} value={type}>
              {t(`enums.${type}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['name_agency'],
      rules: [{ required: true }]
    },
    {
      name: ['email']
    },
    {
      name: ['transmission_code'],
      rules: [
        { required: true },
        { len: payingAgencyType?.startsWith('AMO') ? 9 : 8 }
      ]
    },
    {
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select loading={isLoading}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['follow_up_prodcedure'],
      input: (
        <Select
          loading={isLoading}
          onChange={(value) => {
            setProcedure(value);
          }}
        >
          {(enums?.procedures || []).map((item) => (
            <Select.Option key={item} value={item}>
              {t(`enums.${item}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    ...(procedure === 'COURRIER_POSTAL'
      ? [
          {
            label: 'address.number',
            name: ['address', 'number']
          },
          {
            label: 'address.street',
            name: ['address', 'street']
          },
          {
            label: 'address.additional',
            name: ['address', 'additional']
          },
          {
            label: 'address.postcode',
            name: ['address', 'postal_code']
          },
          {
            label: 'address.city',
            name: ['address', 'city']
          }
        ]
      : []),
    ...(procedure === 'FORMULAIRE_EN_LIGNE'
      ? [
          {
            label: 'procedure_url',
            name: ['procedure_url']
          }
        ]
      : []),
    ...(procedure === 'MAIL'
      ? [
          {
            label: 'procedure_email',
            name: ['procedure_email']
          }
        ]
      : [])
  ];

  return {
    payingAgencyfields
  };
};
