import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload, DatePicker, Select, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { noLabelLayout } from '../../utils/constants/formLayout';
import { AddIcon } from '../../utils/constants/customIcons';

const { RangePicker } = DatePicker;
const { Option } = Select;

export const useFields = (
  openDrawer,
  id_prescription,
  purpose,
  patients,
  centers,
  prescribers,
  setFileList,
  fileList,
  uploadOrDeleteFile,
  isLoading
) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getSelectOptions = useCallback(() => {
    setIsFieldsLoading(true);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);
  const fields = [
    {
      name: ['prescription_file'],
      rules: [{ required: true }],
      input: (
        <Upload
          multiple
          maxCount={2}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...fileList];
              newFile.push(file);
              setFileList(newFile);
            } else {
              uploadOrDeleteFile(
                file,
                id_prescription,
                'upload',
                'prescription_file'
              );
            }
            return false;
          }}
          onRemove={(doc) => {
            if (purpose !== 'edit') {
              const index = fileList.indexOf(doc);
              const newFile = fileList.slice();
              newFile.splice(index, 1);
              setFileList(newFile);
            } else {
              uploadOrDeleteFile(
                fileList[
                  fileList.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id_prescription,
                'delete',
                'prescription_file'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (fileList || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['first_period_validity'],
      rules: [{ required: true }],
      input: <RangePicker />
    },
    {
      name: ['second_period_validity'],
      rules: [{ required: true }],
      input: <RangePicker />
    },
    {
      name: ['patient'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(patients || []).map((patient) => (
            <Option key={patient._id} value={patient._id}>
              {patient.first_name} {patient.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['center'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(centers || []).map((center) => (
            <Option key={center._id} value={center._id}>
              {center.center_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['prescriber'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(prescribers || []).map((prescriber) => (
            <Option key={prescriber._id} value={prescriber._id}>
              {prescriber.first_name} {prescriber.last_name} {'/ '}
              {prescriber?.health_insurance_id || 'n/a'} {'/ '}{' '}
              {prescriber?.rpps_number || 'n/a'}{' '}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['new_prescriber'],
      noLabel: true,
      input: (
        <Form.Item {...noLabelLayout}>
          <Button type="add" onClick={() => openDrawer('prescribers')}>
            {t('centers.form.addPrescriber')}
            <AddIcon />
          </Button>
        </Form.Item>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
